SignUpFormWidget.Helpers.Date = {
    /**
     * We allow anniversary years for the current year plus 10
     *
     * @returns int
     */
    getMaxAnniversaryYear() {
        return new Date().getFullYear() + 10;
    }
};
