/**
 * Handles submitting the form.
 *
 * @param token
 * @param $elem
 * @param form
 * @returns {Promise} A promise that resolves when the form is submitted.
 */
SignUpFormWidget.Handlers.submitForm = (token, $elem, form) => {
    const SOURCE_NAMES = { POPUP: 'Popup Form', INLINE: 'Inline Form', FLYOUT: 'Flyout Form', BAR: 'Banner Form' };
    const deferred = $.Deferred();

    const { form_id, group_id, form_index } = form;
    const recaptcha_key = SignUpFormWidget.Handlers.Config.getRecaptchaKey();
    const contact = SignUpFormWidget.Helpers.Collector.getFormInputs(form);
    contact.source_name = SOURCE_NAMES[form.type] ? SOURCE_NAMES[form.type] : 'Unknown';

    let data = {
        group_id,
        contact,
    };

    if (recaptcha_key !== null) {
        // if recaptcha is in use, send the fields, otherwise skip them so stuff doesn't blow up catastrophically
        data.token = token;
        data.recaptcha_key = recaptcha_key;
        data.is_test = SignUpFormWidget.Helpers.Env.isUsingTestRecaptchaKey();
    }

    // check for resci cookie and exist pass the value as data param.
    const cookies = SignUpFormWidget.Helpers.Cookie.getCookies();
    const resciCookie = cookies.rsci_vid;
    if(resciCookie && resciCookie !== '') {
        data.rsci_vid = resciCookie;
    }

    let xhr = $.ajax({
        type: 'POST',
        crossDomain: true,
        url: `https://web.archive.org/web/20211206224538/https://visitor2.${SignUpFormWidget.env}constantcontact.com/api/v1/signup_forms/${form_id}`,
        contentType: 'application/json',
        data: JSON.stringify(data),
    });

    xhr.done(() => {
        $elem.find(`#success_message_${form_index}`).show();
        $elem.find(`#ctct_form_${form_index}`).hide();
        deferred.resolve();
    });

    xhr.fail((jqXHR, textStatus, errorThrown) => {
        let errors;
        try {
            errors = JSON.parse(jqXHR.responseText);
        }
        catch (e) {
            errors = [];
        }
        if (!_.isArray(errors) || errors.length === 0) {
            errors = [{
                error_key: 'contacts.client.validation.error',
                error_codes: 'network',
                error_message: errors.message || null // possible scenario for resci custom fields
            }];
        }
        SignUpFormWidget.Handlers.Error.display(form, errors);
        $elem.find('.ctct-form-button').removeAttr('disabled');
        deferred.reject(errors);
    });

    xhr.complete(() => {
        $elem.find('.ctct-form-button').removeAttr('disabled');
    });

    return deferred.promise();
};
