/**
 * This banner plugin is meant to be able to be broken out into a standalone widget in the
 * future. Its modeled after the jQuery widget pattern.
 *
 * Please keep it generic :)
 */
 ;((($, window, document, undefined) => {
    const pluginName = 'ctctBannerForm';
    const toggleVisibilityClass = 'ctct-banner-is-visible';

    const defaults = {
        namespace: 'cats',
        autoOpen: true,
        timeDelay: 5000, // 5 seconds in milliseconds
        exitIntent: false,
        closeCallback: () => {},
        isEligible: () => { return true; },
    };

    class CtctBannerForm {
        constructor(element, options = {}) {
            this.$element = $(element);
            this.options = $.extend({}, defaults, options);

            this._defaults = defaults;
            this._name = pluginName;

            this._isShown = false;
            this.shownEventType = 'NONE';

            this.init();
        }

        init() {
            this.$element.find('.js-banner-close').on(`click.${pluginName}.${this.options.namespace}`, () => {
                this.hide();
            });
            $('body').on(`keydown.${pluginName}.${this.options.namespace}`, (e) => {
                const charCode = (typeof e.which === 'number') ? e.which : e.keyCode;
                if (charCode === 27) { //esc
                    this.hide();
                }
            });

            this.$element.parent().find('.ctct-banner-trigger').on(`click.${pluginName}.${this.options.namespace}`, (e) => {
                this.show('button');
            });

            if (this.options.autoOpen) {
                this.show('timer');
            }
        }

        destroy() {
            if (this.delayTimerId) {
                window.clearTimeout(this.delayTimerId);
            }
            this.hide();
            
            this.$element.find('.js-banner-close').off(`click.${pluginName}.${this.options.namespace}`);
            $('body').off(`keydown.${pluginName}.${this.options.namespace} click.${pluginName}.${this.options.namespace}`);

        }

        show(eventType, ignoreTimeDelay = false) {
            if (this.options.isEligible(eventType) && !this.$element.hasClass(toggleVisibilityClass)) {
                if (!ignoreTimeDelay && this.options.timeDelay > 0) {
                    this.delayTimerId = window.setTimeout(() => {
                        this.shownEventType = eventType;
                        this.$element.addClass(toggleVisibilityClass);
                    }, this.options.timeDelay);
                }
                else {
                    this.shownEventType = eventType;
                    this.$element.addClass(toggleVisibilityClass);
                }
            }
        }

        hide() {
            if (this.$element.hasClass(toggleVisibilityClass)) {
                this.$element.removeClass(toggleVisibilityClass);
                this.options.closeCallback(this.shownEventType);
                this.$element.find('.ctct-banner-inner').css('z-index', -1);
            }
        }

        getShownEventType() {
            return this.shownEventType;
        }
    }

    $.fn[ pluginName ] = function (options) {
        const args = arguments;

        if (options === undefined || typeof options === 'object') {
            return this.each(function () {
                if (!$.data(this, `plugin_${pluginName}`)) {
                    $.data(this, `plugin_${pluginName}`, new CtctBannerForm(this, options));
                }
            });
        }
        else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            let returns;

            this.each(function () {
                const instance = $.data(this, `plugin_${pluginName}`);
                if (instance instanceof CtctBannerForm && typeof instance[options] === 'function') {
                    returns = instance[options](...Array.prototype.slice.call(args, 1));
                }
                if (options === 'destroy') {
                    $.data(this, `plugin_${pluginName}`, null);
                }
            });

            return returns !== undefined ? returns : this;
        }
    };
})(jQuery, window, document));
