SignUpFormWidget.Handlers.Error = (function() {
    let api;

    const ERROR_MAP = {
        email_address: {
            format: 'invalid_email_address',
        },
        anniversary: {
            format: 'invalid_anniversary_format',
            before: 'invalid_anniversary_year',
            after: 'invalid_anniversary_year',
        },
        birthday: {
            format: 'invalid_birthday_format',
            blank: 'invalid_birthday_format',
        },
        custom_field_date: {
            format: 'invalid_custom_date_format',
            before: 'invalid_custom_date_format',
            after: 'invalid_custom_date_year',
        },
        DATETIME: {
            format: 'invalid_custom_date_format',
            before: 'invalid_custom_date_format',
            after: 'invalid_resci_custom_date_year',
        },
        DATE: {
            format: 'invalid_custom_date_format',
            before: 'invalid_custom_date_format',
            after: 'invalid_resci_custom_date_year',
        },
        list_memberships: {
            is_missing: 'list_membership_missing',
        },
        is_missing: 'required_field_missing',
        not_a_date: 'invalid_date',
        network: 'general_network_error',
        unknown: 'general_field_error',
    };

    /*
     *
     * @param {String} code The error string
     * @param {String} field The field name string
     * @returns {String} The i18n key for the error we want to display to user.
     */
    function _mapErrors(code, field) {
        // rather than store every field in the map, we handle this case upfront.
        if (code === 'too_long') {
            return `${field}_too_long`;
        }
        else if (ERROR_MAP[field] && ERROR_MAP[field][code]) {
            return ERROR_MAP[field][code];
        }
        else if (ERROR_MAP[code]) {
            return ERROR_MAP[code];
        }
        else {
            return ERROR_MAP['unknown'];
        }
    }

    function _normalizeErrors(unmappedErrors, form) {
        return unmappedErrors.map(err => {
            // translate custom field names to type (String or Date)
            if (!_.isEmpty(err.error_field)) {
                if (err.error_field.indexOf('custom_fields.') > -1) {
                    let name = err.error_field.split('.')[1];
                    let type = SignUpFormWidget.Handlers.Config.getCustomFieldType(form, name);
                    err.error_field = `${type}_${name}`;
                    err.custom_field_type = type;
                }
                /*
                 * If fields are birthday_day and birthday_month, map them
                 * to the same key, then render whichever one comes last.
                 *
                 * Coded this way for performance reasons.
                 */
                err.error_field = err.error_field
                    .replace('birthday_day', 'birthday')
                    .replace('birthday_month', 'birthday')
                    .replace('street_address.', '')
                    .replace('company_name', 'company')
                    .replace('phone_number', 'phone');
            }

            return err;
        });
    }

    function _getErrorText(err) {
        const { error_codes, error_field, custom_field_type, resci_custom_field_type } = err;
        let errKey = 'general_submit_error';

        // for the i18n key, parse out custom field type if it's a custom field
        if (!_.isEmpty(error_codes)) {
            const fieldCopyKey = custom_field_type || resci_custom_field_type || error_field;
            errKey = _mapErrors(error_codes[0], fieldCopyKey);
        }

        return SignUpFormWidget.Helpers.i18n.translate(errKey);
    }

    function _attachErrorClearCallback($form, form_index, elem) {
        let tag = elem.tagName, type = elem.type, eventType;

        // deduce which event to bind to for this element
        if ((tag === 'INPUT' && _.contains([ 'number', 'checkbox' ], type)) ||
            tag === 'SELECT') {
            eventType = 'change';
        } else if (tag === 'INPUT' && _.contains([ 'text', 'email', 'tel' ], type)) {
            eventType = 'keyup';
        }

        // prevents double binding if {event}.clear is already bound on that element
        $(elem).off(`${eventType}.clear`);

        // bind to either keyup.clear or change.clear depending on the element
        $(elem).on(`${eventType}.clear`, function() {
            let $errorTextElement;
            // lists field renders errors on the level of the input's parent
            if (this.tagName === 'INPUT' && this.type === 'checkbox') {
                $errorTextElement = $(this).parent().siblings('.ctct-form-errorMessage');
            } else {
                $errorTextElement = $(this).siblings('.ctct-form-errorMessage');
            }

            // hide the error message for the edited element
            $errorTextElement.hide();
            // hide global failure messages
            $form.find(`#error_message_${form_index}`).hide();
            $form.find(`#network_error_message_${form_index}`).hide();

            // take element out of error state and unbind event
            $(this).removeClass('is-error');
            $(this).off(`${eventType}.clear`);

            // if element is a date, we must take all 2-3 inputs out of error state and unbind
            let isDate = this.placeholder === 'MM' || this.placeholder === 'DD' || this.placeholder === 'YYYY';
            if (isDate) {
                let $adjacentInputs = $(this).siblings('input');
                $adjacentInputs.removeClass('is-error');
                $adjacentInputs.off(`${eventType}.clear`);
            }
        });
    }

    api = {
        /*
         * Renders a list of errors on the form
         *
         * @param {Object} form The form that failed to submit
         * @param {Array} unmappedErrors A list of errors to render
         */
        display(form, unmappedErrors) {
            const { form_index } = form;

            // normalize error format, whether they're client or server-made
            const errors = _normalizeErrors(unmappedErrors, form);

            let $form = $(`#ctct_form_${form_index}`);

            // loop over errors and display each one
            errors.forEach(err => {
                const { error_field, error_codes } = err;
                const errText = _getErrorText(err);

                if (!_.isEmpty(error_field)) {
                    // append text to the error container for this field
                    let problemField = $form.find(`#${error_field}_field_${form_index}`);
                    problemField.find('.ctct-form-errorMessage').html(errText).show();

                    // style the input or select to show error
                    problemField.find('input:visible, select:visible').addClass('is-error');
                }
                else if (err.error_codes === 'network') {
                    // Will show actual error message instead of time out message.
                    // This needs to be reveiwed.
                    if(err.error_message) {
                        let message = err.error_message;
                        // If resci error, format to show in new lines.
                        // Though the string split and this way fo changing the string is not great practise,
                        // But couldn't find better way as resci API is giving the multiple field errors as single string.
                        if(err.error_message.indexOf('custom_attribute') > -1) {
                            const resciErrors = message.split('\n');
                            let formattedMessage = `<h4>${resciErrors[0]}</h4>`;
                            resciErrors.splice(1).forEach(error => {
                                if(error.indexOf('VARCHAR') > -1) {
                                    const fieldName = `${error.split('VARCHAR')[0].trim()} `;
                                    const validationMessage = error.split('VARCHAR')[1];
                                    formattedMessage += `<strong>${fieldName}</strong><span>${validationMessage}</span><br/>`;
                                } else {
                                    // since validation message does not have date type part of it.
                                    const fieldName = `${error.split('Invalid format')[0].trim()} `;
                                    const validationMessage = `Invalid format ${error.split('Invalid format')[1]}`;
                                    formattedMessage += `<strong>${fieldName}</strong><span>${validationMessage}</span><br/>`;
                                }
                            });
                            message = formattedMessage;
                        }
                        $form.find(`#network_error_message_${form_index} .ctct-form-errorMessage`).html(message);
                    }
                    $form.find(`#network_error_message_${form_index}`).show();
                }
                else { // show generic error
                    $form.find(`#error_message_${form_index}`).show();
                }
            });

            /*
             * Attach self-unbinding handler to clear all errors
             * jQuery's "one" will not work as it executes
             * once per element per event, and we have multiple elements
             * and events.
             */
            $form.find('input, select').each((idx, elem) => {
                _attachErrorClearCallback($form, form_index, elem);
            });
        },
    };

    return api;
})();
