// Helper functions to condionally render email field based on if title and description fields are empty
SignUpFormWidget.Helpers.BannerHelper = {
    renderEmailField: (form) => {
        let $elem = $(`#ctct_form_${form.form_index}`);
        $elem.find('.ctct-email-field-wrapper').empty();
        $elem.find('.ctct-email-field-wrapper').append(
            `
            <div id="email_address_field_${form.form_index}" class="ctct-form-field ctct-email-field-non-empty-title-description" >
                <label data-qe-id="form-label-email" id="email_address_label_${form.form_index}" for="email_address_${form.form_index}" class="ctct-form-label ctct-form-required">${SignUpFormWidget.Helpers.i18n.translate('field_email_address')}</label>
                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>
                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_${form.form_index}" type="email" name="email_address" value="" maxlength="80">
            </div>
            `
        );
    },

    renderEmailFieldEmptyTitleDescription: (form) => {
        let $elem = $(`#ctct_form_${form.form_index}`);
        $elem.find('.ctct-email-field-wrapper').empty();
        $elem.find('.ctct-email-field-wrapper').append(
            `
            <div class="ctct-email-field-empty-title-description">
            <div id="email_address_field_${form.form_index}" class="ctct-form-field" >
                <label data-qe-id="form-label-email" id="email_address_label_${form.form_index}" for="email_address_${form.form_index}" class="ctct-form-label ctct-form-required">${SignUpFormWidget.Helpers.i18n.translate('field_email_address')}</label>
                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>
                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_${form.form_index}" type="email" name="email_address" value="" maxlength="80">
                </div>
                <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline">${form.config.button.text}</button>
            </div>
            `
        );
    }

};
