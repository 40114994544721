/**
 * @param {Object} config The config object.
 * @param {Boolean} [config.debug] The debug logging flag.
 * @param {String} config.companyName The Company Name to be used in default text.
 * @param {String} config.privacyUrl The privacy URL to be used
 * @param {String} config.countries The list of companies, which should match the format from account-svc
 * @param {String} config.statesProvinces The list of states, which should match the format from account-svc
 * @param {String} [namespace] An optional namespace.
 * @returns {Promise} Returns a promise that will resolve with an instance of {@link SignUpFormWidget.Api.Preview}
 */
SignUpFormWidget.Api.instance = (config, namespace) => {
    let def;

    def = $.Deferred();

    if (namespace === undefined && config === undefined) {
        namespace = 'preview_NO_CONFIG';
    }
    else {
        namespace = namespace || `preview_${config.selector}`;
    }

    if (_.isUndefined(config) || !_.isObject(config) || _.isEmpty(config)) {
        def.reject('config is required');
    }
    else { // check for required config items
        if (config.companyName === undefined || !_.isString(config.companyName)) {
            def.reject('companyName must be a string');
        }

        if (config.privacyUrl === undefined || !_.isString(config.privacyUrl)) {
            def.reject('privacyUrl must be a string');
        }

        if (config.countries === undefined || !_.isArray(config.countries)) {
            def.reject('countries must be an array');
        }

        if (config.statesProvinces === undefined || !_.isArray(config.statesProvinces)) {
            def.reject('statesProvinces must be an array');
        }
    }

    if (!_.isString(namespace)) {
        def.reject('namespace must be a string');
    }

    if (def.state() !== 'rejected') {
        // set config items
        SignUpFormWidget.Handlers.Config.setCompanyName(config.companyName);
        SignUpFormWidget.Handlers.Config.setPhysicalAddress(config.physicalAddress);
        SignUpFormWidget.Handlers.Config.setOrgWebsite(config.orgWebsite);
        SignUpFormWidget.Handlers.Config.setPrivacyUrl(config.privacyUrl);
        SignUpFormWidget.Handlers.Config.setCustomPrivacyUrl(config.customPrivacyPolicyUrl);
        SignUpFormWidget.Handlers.Config.setFinePrintText(config.finePrintText);
        SignUpFormWidget.Handlers.Config.setServiceUrl('https://web.archive.org/web/20211206224538/https://www.constantcontact.com/legal/service-provider');
        SignUpFormWidget.Handlers.Config.setCountries(config.countries);
        SignUpFormWidget.Handlers.Config.setStates(config.statesProvinces);

        // create Preview instance
        if (!SignUpFormWidget._instances[namespace]) {
            // only create each instance once
            SignUpFormWidget._instances[namespace] = new SignUpFormWidget.Instance.Preview(def, config, namespace);
        }
        else if (config.debug) {
            SignUpFormWidget.Helpers.Logger.info(`Preview["${namespace}"] previously instantiated! Doing nothing.`);
        }
    }

    return def.promise();
};

/**
 * Destroys & cleans up all current instances.
 * @type {{instance: SignUpFormWidget.Api.destroyAll}}
 */
SignUpFormWidget.Api.destroyAll = () => {
    if (SignUpFormWidget._instances) {
        const keys = Object.keys(SignUpFormWidget._instances);
        _.each(keys, (key) => {
            SignUpFormWidget._instances[ key ].destroy();
            delete SignUpFormWidget._instances[ key ];
        });
    }
};
