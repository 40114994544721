SignUpFormWidget.Helpers.Env = {
    /**
     * Sets up SignUpFormWidget.assetEnv and SignUpFormWidget.env for general use.
     *
     * @param url
     */
    parseEnv(url) {
        if (url.indexOf('ctctcdn.com/js') === -1) {
            // if its not on our CDN as /js , its being run locally or in a pipeline
            SignUpFormWidget.env = 'l1.';
            SignUpFormWidget.assetEnv = '-dev';
        }
        else {
            // grab l1/s1/current from URL
            let env = url.match(/.*\/(l1|s1|current)\/.*/)[ 1 ];

            if (env === 'l1') {
                SignUpFormWidget.env = `${env}.`;
                SignUpFormWidget.assetEnv = '-dev';
            }
            else if (env === 's1') {
                SignUpFormWidget.env = `${env}.`;
                SignUpFormWidget.assetEnv = '-stage';
            }
            else { // assume anything else is prod
                SignUpFormWidget.env = '';
                SignUpFormWidget.assetEnv = '';
            }
        }
    },

    /**
     * Tells visitor2 whether the token sent via signup was procured via test key
     */
    isUsingTestRecaptchaKey() {
        if (SignUpFormWidget.env === '') {
            // never ever ever use test key in prod
            return false;
        }
        else {
            return SignUpFormWidget.use_real_recaptcha_key !== true;
        }
    },
};
