SignUpFormWidget.Helpers.Devices = ((() => {
    /**
     * Helper function to get deep properties to return 'undefined' for no match,
     * instead of an error.
     * @param obj The object to check.
     * @param args The property key path to check
     * @return {any} Value or 'undefined'
     */
    const _getNested = (obj, ...args) => {
        return args.reduce((obj, level) => obj && obj[level], obj);
    };

    /**
     * Checks device type for the form, compares it to current device.
     * @param formConfig form config object.
     * @return {boolean} Whether the form should be shown on this device or not.
     */
    const showOnThisDevice = (formConfig) => {
        let show = false;
        // device setting is saved in form model in listgrowth-static
        let deviceSetting = _getNested(formConfig, 'config', 'devices', 'selected');
        if (typeof deviceSetting === 'undefined') {
            // if device setting is not found, show everywhere by default
            deviceSetting = 'desktop_and_mobile';
        }
        // determine device type
        const isMobileDevice = (/(android|bb\d+|meego).+mobile|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
        const isDesktopDevice = !isMobileDevice;
        // device display options: desktop, mobile, desktop_and_mobile
        const showMobile = (/mobile/i.test(deviceSetting.toLowerCase()));
        const showDesktop = (/desktop/i.test(deviceSetting.toLowerCase()));

        if (isMobileDevice && showMobile) {
            show = true;
        }
        if (isDesktopDevice && showDesktop) {
            show = true;
        }

        return show;
    };

    return {
        showOnThisDevice,
    };
})());
