SignUpFormWidget.Render = {
    /**
    *
    * @param {jQuery object} $elem The jQuery-wrapped element to render into.
    * @param {Object} formConfig The signup form config to define form copy and style
    * @returns {undefined}
    */
    inlineForm($elem, formConfig, companyInfo) {
        let deferred = $.Deferred();

        if ($elem.length === 0) {
            deferred.reject('Element doesn\'t exist');
        }
        if (!_.isObject(formConfig)) {
            deferred.reject('Config is required');
        }
        if (deferred.state() === 'rejected') {
            return deferred.promise();
        }

        try {
            SignUpFormWidget.Helpers.i18n.load(formConfig.config.language);
            const activeLanguage = SignUpFormWidget.Helpers.i18n.getActiveLanguage();
            const templateIdentifier = `dist/templates/${activeLanguage}/inline_form.html`;

            let config = SignUpFormWidget.Handlers.Config.getRenderConfig(formConfig, companyInfo);
            const requiredFields = SignUpFormWidget.Handlers.Config.getRequiredFields(formConfig);

            // load styles before drawing to minimize flashing/reflow of the DOM
            SignUpFormWidget.Handlers.Style.buildInlineStyles(formConfig);

            // draw element in DOM
            $elem.html(SignUpFormWidget.JST[templateIdentifier](config));

            if (_.contains(config.contact_fields, 'country') && _.contains(config.contact_fields, 'state')) {
                // Attach change events to the form's specific country select
                // Note that this only happens once at initial form render
                this.attachCountryChangeEvents($elem, formConfig, config);

                this.renderStateSelect($elem, formConfig, config);
            }

            // Prevents typing anything but digits in date fields
            this.restrictDateInputs($elem);
            // process required contact fields
            _.each(requiredFields, (requiredFieldType) => {
                $elem.find(`#${requiredFieldType}_label_${config.form_index}`).addClass('ctct-form-required');
            });

            deferred.resolve(config);
        } catch(err) {
            deferred.reject(`Error rendering template[${formConfig.name}]: ${err.message}`, err);
        }

        return deferred.promise();
    },

    /**
    *
    * @param {jQuery object} $elem The jQuery-wrapped element to render into.
    * @param {Object} formConfig The signup form config to define form copy and style
    * @returns {Promise}
    */
    popupForm($elem, formConfig, companyInfo) {
        const styleMethod = 'buildPopupStyles';
        const outerTemplate = 'popup_wrapper.html';
        const innerTemplate = 'popup_form.html';

        return this._doRender($elem, formConfig, companyInfo, styleMethod, outerTemplate, innerTemplate);
    },

    flyoutForm($elem, formConfig, companyInfo) {
        const styleMethod = 'buildFlyoutStyles';
        const outerTemplate = 'flyout_wrapper.html';
        const innerTemplate = 'flyout_form.html';

        return this._doRender($elem, formConfig, companyInfo, styleMethod, outerTemplate, innerTemplate);
    },

    barForm($elem, formConfig, companyInfo) {
        const styleMethod = 'buildBannerStyles';
        const outerTemplate = 'banner_wrapper.html';
        const innerTemplate = 'banner_form.html';

        return this._doRender($elem, formConfig, companyInfo, styleMethod, outerTemplate, innerTemplate);
    },

    /**
    *
    * @param {jQuery object} $elem The jQuery-wrapped element to render into.
    * @param {Object} formConfig The signup form config to define form copy and style
    * @param {String} styleMethod method to use for setting up style overrides
    * @param {String} outerTemplate name of template for form wrapper
    * @param {String} innerTemplate method to use for rendering form contents
    * @returns {Promise}
    */
    _doRender($elem, formConfig, companyInfo, styleMethod, outerTemplate, innerTemplate) {
        const deferred = $.Deferred();
        if ($elem.length === 0) {
            deferred.reject('Element doesn\'t exist');
        }
        if (!_.isObject(formConfig)) {
            deferred.reject('Config is required');
        }
        if (deferred.state() === 'rejected') {
            return deferred.promise();
        }

        try {
            SignUpFormWidget.Helpers.i18n.load(formConfig.config.language);

            const config = SignUpFormWidget.Handlers.Config.getRenderConfig(formConfig, companyInfo);
            const requiredFields = SignUpFormWidget.Handlers.Config.getRequiredFields(formConfig);

            // load styles before drawing to minimize flashing/reflow of the DOM
            SignUpFormWidget.Handlers.Style[styleMethod](formConfig);

            const outerTemplatePath = this.localizedTemplate(outerTemplate);
            $elem.html(SignUpFormWidget.JST[outerTemplatePath](config));

            const innerTemplatePath = this.localizedTemplate(innerTemplate);
            $elem.find('.ctct-form-container').html(SignUpFormWidget.JST[innerTemplatePath](config));

            if (_.contains(config.contact_fields, 'country') && _.contains(config.contact_fields, 'state')) {
                // Attach change events to the form's specific country select
                // Note that this only happens once at initial form render
                this.attachCountryChangeEvents($elem, formConfig, config);

                this.renderStateSelect($elem, formConfig, config);
            }

            // Prevents typing anything but digits in date fields
            this.restrictDateInputs($elem);

            // process required contact fields
            _.each(requiredFields, (requiredFieldType) => {
                $elem.find(`#${requiredFieldType}_label_${config.form_index}`).addClass('ctct-form-required');
            });

            deferred.resolve(config);
        } catch(err) {
            deferred.reject(`Error rendering form ${formConfig.name}: ${err.message}`, err);
        }

        return deferred.promise();
    },

    renderStateSelect($elem, form, config) {
        let countrySelectId = `#country_${form.form_index}`;
        let selectedCountry = $elem.find(countrySelectId).val();

        let stateInputElem = `input.state_${form.form_index}`;
        let stateSelectElem = `select.state_${form.form_index}`;

        let states = config._defaults.states;
        if (states[selectedCountry] === undefined) {
            $(stateInputElem).show();
            $(stateInputElem).attr( "id", `state_${form.form_index}`);
            $(stateSelectElem).hide();
            $(stateSelectElem).removeAttr("id");
        } else {
            let selectDefaultOption = `<option value="" selected="selected">--</option>`;
            let stateSelectContents = selectDefaultOption;
            _.each(states[selectedCountry], function(state) {
                let stateOption = `<option value="${state.stateProvCode}">${state.display}</option>`;
                stateSelectContents += stateOption;
            });
            $elem.find(stateSelectElem).html(stateSelectContents);
            $elem.find(stateInputElem).hide();
            $elem.find(stateInputElem).removeAttr("id");
            $elem.find(stateSelectElem).show();
            $elem.find(stateSelectElem).attr("id", `state_${form.form_index}`);
        }
    },

    attachCountryChangeEvents($elem, form, config) {
        let thisFormId = `country_${form.form_index}`;
        $elem.find(`#${thisFormId}`).change(() => {
            this.renderStateSelect($elem, form, config);
        });
    },

    restrictDateInputs($elem) {
        let dateInputs = $elem.find('input[type="number"]');
        dateInputs.on('keypress paste', function(e) {
            // Check if input is tab or backspace
            if ((e.which !== 9) && (e.which !== 8) && (e.which !== 13)) {
                // Check if input is non-digit character
                if (!String.fromCharCode(e.which).match(/^\d+$/)) {
                    return false;
                }
            }
        });
    },

    localizedTemplate(templateName) {
        return `dist/templates/${SignUpFormWidget.Helpers.i18n.getActiveLanguage()}/${templateName}`;
    },
};
