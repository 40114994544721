/**
 * This is the instance that gets instantiated with a namespace and returned.
 *
 * @name SignUpFormWidget.Instance.Preview
 * @param {Promise} deferred The instance promise that needs resolving.
 * @param {Object} config
 * @param {Boolean} [config.debug] Flag for enabling debug logging
 * @param {String} config.selector
 * @param {String} namespace
 * @instance
 * @class
 */
SignUpFormWidget.Instance.Preview = (deferred, config, namespace) => {
    let api = {};
    let destroyed = false;
    let $elem;
    let previousFlyoutConfig = { animated: null, position: null };
    let formPosition;

    if (config.debug) {
        SignUpFormWidget.Helpers.Logger.info(`Creating preview["${namespace}"] with config:`, config);
    }

    if (deferred.state() !== 'rejected') {
        if (config.debug) {
            SignUpFormWidget.Helpers.Logger.info(`Preview["${namespace}"] ready!`);
        }

        deferred.resolve(api);
    }

    /**
     * Destroys this instance of Preview.
     * @returns {Promise} Promise that will resolve when its done, or reject on fail/error.
     * @name SignUpFormWidget.Api.Preview.destroy
     */
    api.destroy = function() {
        let def = $.Deferred();

        if (config.debug) {
            SignUpFormWidget.Helpers.Logger.info(`.destroy["${namespace}"]:`, arguments);
        }
        if (destroyed) { // if already destroyed, just return
            def.resolve();
            return def.promise();
        }
        destroyed = true; // mark destroyed to reject future calls

        // empty the parent element - must be done after models are cleaned up
        if ($elem) {
            $elem.html('');
        }

        def.resolve();

        return def.promise();
    };

    /**
     * @param {Object} jqElem The jQuery element that the form should be rendered in.
     * @param {Object} formConfig The config object for a specific form.
     * @returns {Promise} Promise that will resolve when the form has been rendered.
     * @name SignUpFormWidget.Api.Preview.previewForm
     */
    api.previewForm = function(jqElem, formConfig) {
        const bannerContactFieldsWarningLength = 1;
        const bannerEmailListsWarningLength = 2;
        let def = $.Deferred();
        if (destroyed) {
            if (config.debug) {
                SignUpFormWidget.Helpers.Logger.info(`.previewForm["${namespace}"]: instance destroyed!`);
            }
            def.reject('instance_destroyed');
            return def.promise();
        }

        if (jqElem instanceof $) {
            $elem = jqElem;
        } else {
            def.reject('object provided must be a jquery element');
            return def.promise();
        }

        if (config.debug) {
            SignUpFormWidget.Helpers.Logger.info(`.previewForm["${namespace}"]:`, arguments);
        }

        // set the form in the config handler
        SignUpFormWidget.Handlers.Config.setForms([ formConfig ]);
        const companyInfo = SignUpFormWidget.Handlers.Config.getCompanyInfo();

        let renderMethod = `${ formConfig.type.toLowerCase() }Form`;
        let promise = SignUpFormWidget.Render[renderMethod]($elem, formConfig, companyInfo);

        promise.done((renderConfig) => {
            if (config.debug) {
                SignUpFormWidget.Helpers.Logger.info(`.previewForm["${namespace}"]: template rendered!`, renderConfig);
            }
            if (formConfig.type === 'POPUP') {
                // make popup visible for preview
                $elem.find('.ctct-popup-wrapper').addClass('ctct-popup-is-visible');
            }
            if (formConfig.type === 'INLINE') {
                $('body').removeClass('flyout-banner-background');
            }
            if (formConfig.type === 'BAR') {
                $('body').addClass('flyout-banner-background');
                const { position }  = formConfig.config.banner;
                if(position === 'bottom' && $elem.find('.ctct-banner-inner').hasClass('ctct-banner-top')) {
                    $elem.find('.ctct-banner-inner').removeClass('ctct-banner-top');
                }
                if(position === 'top' && $elem.find('.ctct-banner-inner').hasClass('ctct-banner-bottom')) {
                    $elem.find('.ctct-banner-inner').removeClass('ctct-banner-bottom');
                }
                $elem.find('.ctct-banner-inner').scroll(() => {
                    formPosition = $elem.find('.ctct-banner-inner').scrollTop();
                });
                $elem.find('.ctct-banner-inner').scrollTop(formPosition);
                // Change button placement if 1 contact field and no title and description.
                if(formConfig.config.title.text === '' && formConfig.config.description.text === '' && formConfig.config.contactFields.length === bannerContactFieldsWarningLength && formConfig.config.emailLists.length < bannerEmailListsWarningLength) {
                    $elem.find('.ctct-submit-inline-default').removeClass('ctct-submit-inline-visible');
                    $elem.find('.ctct-submit-inline-default').addClass('ctct-submit-inline-not-visible');
                    SignUpFormWidget.Helpers.BannerHelper.renderEmailFieldEmptyTitleDescription(formConfig);

                } else {
                    $elem.find('.ctct-submit-inline-default').removeClass('ctct-submit-inline-not-visible');
                    $elem.find('.ctct-submit-inline-default').addClass('ctct-submit-inline-visible');
                    SignUpFormWidget.Helpers.BannerHelper.renderEmailField(formConfig);
                }

                $elem.find('.ctct-banner-inner').addClass(`ctct-banner-${position}`);
                // make banner visible for preview
                $elem.find('.ctct-banner-wrapper').addClass('ctct-banner-is-visible');
            }
            if (formConfig.type === 'FLYOUT') {
                $('body').addClass('flyout-banner-background');
                //if theres an animation do the animation
                const { animated, position }  = formConfig.config.flyout;
                // check that the change was to a flyout property to show animation. Otherwise the animation happens every rerender.
                // if animated and not preview also show the animation
                
                $elem.find('.ctct-flyout-inner').scroll(() => {
                    formPosition = $elem.find('.ctct-flyout-inner').scrollTop();
                });

                $elem.find('.ctct-flyout-inner').scrollTop(formPosition);
                if (!SignUpFormWidget.previewMode){
                    if (formConfig.config.flyout.animated) {
                        const animationClass = SignUpFormWidget.Helpers.FlyoutAnimationHelper.getAnimationClass(position);
                        $elem.find('.ctct-flyout-inner').addClass(animationClass);
                        $elem.find('.ctct-flyout-wrapper').addClass('ctct-flyout-is-visible');
                    }
                }

                else if(formConfig.config.flyout.animated && (animated !== previousFlyoutConfig.animated || position !== previousFlyoutConfig.position) ) {
                    const animationClass =SignUpFormWidget.Helpers.FlyoutAnimationHelper.getAnimationClass(position);
                    $elem.find('.ctct-flyout-inner').addClass(animationClass);
                    $elem.find('.ctct-flyout-wrapper').addClass('ctct-flyout-is-visible');
                }
                else {
                    // make flyout visible normally if no animation
                    $elem.find('.ctct-flyout-wrapper').addClass('ctct-flyout-is-visible');

                }
                previousFlyoutConfig.animated = animated;
                previousFlyoutConfig.position = position;

            }
            def.resolve();
        }).fail((errorMsg) => {
            if (config.debug) {
                SignUpFormWidget.Helpers.Logger.warn(`.previewForm["${namespace}"]: failure rendering template: ${errorMsg}`);
            }
            def.reject(errorMsg);
        });

        return def.promise();
    };

    return api;
};
