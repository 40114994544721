SignUpFormWidget.Helpers.Logger = ((() => {

    let debugMode = false;

    const setDebugMode = (mode = false) => {
        debugMode = mode;
    };

    /**
     * @param {String} messages The messages to log
     */
    const debug = (...messages) => {
        if (debugMode && window.console && window.console.debug && typeof window.console.debug === 'function') {
            window.console.debug(...messages);
        }
    };

    /**
     * @param {String} messages The messages to log
     */
    const info = (...messages) => {
        if (debugMode && window.console && window.console.info && typeof window.console.info === 'function') {
            window.console.info(...messages);
        }
    };

    /**
     * @param {String} messages The messages to log
     */
    const warn = (...messages) => {
        if (window.console && window.console.warn && typeof window.console.warn === 'function') {
            window.console.warn(...messages);
        }
    };

    /**
     * @param {String} messages The messages to log
     */
    const error = (...messages) => {
        if (window.console && window.console.error && typeof window.console.error === 'function') {
            window.console.error(...messages);
        }
    };

    /**
     * @param {String} level The level to log at (debug, info, etc)
     * @param {String} messages The messages to log
     */
    const log = (level, ...messages) => {
        switch(level) {
            case 'debug':
            case 'DEBUG':
                debug(...messages);
                break;
            case 'warn':
            case 'WARN':
                warn(...messages);
                break;
            case 'error':
            case 'ERROR':
                error(...messages);
                break;
            default:
                info(...messages);
                break;
        }
    };

    return {
        setDebugMode,
        debug,
        info,
        warn,
        error,
        log,
    };
})());
