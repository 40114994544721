SignUpFormWidget = SignUpFormWidget || {};
SignUpFormWidget["JST"] = SignUpFormWidget["JST"] || {};

SignUpFormWidget["JST"]["dist/templates/da/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tak for tilmeldingen!</h2>\n        <p class="ctct-form-text">Du kan når som helst framelde dig via linket Frameld mig nederst i hver e-mail.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mail-adresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg et land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gade/vej</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">By</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg en region/landsdel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Firma</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jobtitel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdato</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-mail-lister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi beklager, vi kunne ikke gennemføre din tilmelding. Kontakt os, så vi kan løse problemet.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails er en tjenesteydelse fra Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vores Privatlivspolitik.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tak for tilmeldingen!</h2>\n        <p class="ctct-form-text">Du kan når som helst framelde dig via linket Frameld mig nederst i hver e-mail.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mail-adresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg et land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gade/vej</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">By</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg en region/landsdel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Firma</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jobtitel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdato</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-mail-lister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi beklager, vi kunne ikke gennemføre din tilmelding. Kontakt os, så vi kan løse problemet.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails er en tjenesteydelse fra Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vores Privatlivspolitik.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Tak for tilmeldingen!</h2>\n            <p class="ctct-form-text">Du kan når som helst framelde dig via linket Frameld mig nederst i hver e-mail.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mail-adresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternavn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg et land</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gade/vej</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">By</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg en region/landsdel</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Firma</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jobtitel</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdato</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-mail-lister</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Vi beklager, vi kunne ikke gennemføre din tilmelding. Kontakt os, så vi kan løse problemet.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails er en tjenesteydelse fra Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vores Privatlivspolitik.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tak for tilmeldingen!</h2>\n        <p class="ctct-form-text">Du kan når som helst framelde dig via linket Frameld mig nederst i hver e-mail.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mail-adresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg et land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gade/vej</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">By</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vælg en region/landsdel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Firma</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jobtitel</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdato</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-mail-lister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi beklager, vi kunne ikke gennemføre din tilmelding. Kontakt os, så vi kan løse problemet.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails er en tjenesteydelse fra Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vores Privatlivspolitik.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/da/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Vielen Dank für Ihre Anmeldung!</h2>\n        <p class="ctct-form-text">Über den Link zur Austragung aus der Mailingliste in jeder E-Mail können Sie sich jederzeit austragen.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-Mail-Adresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vorname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nachname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Land auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Straße</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stadt</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bundesland/Kanton auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postleitzahl</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Unternehmen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Position</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Geburtsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubilumsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-Mail-Listen</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Der Anmeldevorgang konnte nicht abgeschlossen werden. Bitte wenden Sie sich an uns, damit dieses Problem behoben wird.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-Mails werden von Constant Contact verarbeitet.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Unsere Datenschutzrichtlinie.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Vielen Dank für Ihre Anmeldung!</h2>\n        <p class="ctct-form-text">Über den Link zur Austragung aus der Mailingliste in jeder E-Mail können Sie sich jederzeit austragen.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-Mail-Adresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vorname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nachname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Land auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Straße</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stadt</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bundesland/Kanton auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postleitzahl</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Unternehmen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Position</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Geburtsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubilumsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-Mail-Listen</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Der Anmeldevorgang konnte nicht abgeschlossen werden. Bitte wenden Sie sich an uns, damit dieses Problem behoben wird.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-Mails werden von Constant Contact verarbeitet.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Unsere Datenschutzrichtlinie.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Vielen Dank für Ihre Anmeldung!</h2>\n            <p class="ctct-form-text">Über den Link zur Austragung aus der Mailingliste in jeder E-Mail können Sie sich jederzeit austragen.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-Mail-Adresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vorname</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nachname</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Land auswählen</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Straße</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stadt</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bundesland/Kanton auswählen</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postleitzahl</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Unternehmen</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Position</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Geburtsdatum</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubilumsdatum</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-Mail-Listen</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Der Anmeldevorgang konnte nicht abgeschlossen werden. Bitte wenden Sie sich an uns, damit dieses Problem behoben wird.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-Mails werden von Constant Contact verarbeitet.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Unsere Datenschutzrichtlinie.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Vielen Dank für Ihre Anmeldung!</h2>\n        <p class="ctct-form-text">Über den Link zur Austragung aus der Mailingliste in jeder E-Mail können Sie sich jederzeit austragen.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-Mail-Adresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Vorname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nachname</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Land auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Straße</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stadt</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bundesland/Kanton auswählen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postleitzahl</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Unternehmen</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Position</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Geburtsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubilumsdatum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-Mail-Listen</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Der Anmeldevorgang konnte nicht abgeschlossen werden. Bitte wenden Sie sich an uns, damit dieses Problem behoben wird.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-Mails werden von Constant Contact verarbeitet.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Unsere Datenschutzrichtlinie.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/de/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Thanks for signing up!</h2>\n        <p class="ctct-form-text">You can unsubscribe at any time using the Unsubscribe link at the bottom of every email.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Email</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">First Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Last Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Phone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Country</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Street</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">City</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">State/Province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postal Code</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Company</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Job Title</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Birthday</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversary</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Email Lists</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Sorry, we could not complete your sign-up. Please contact us to resolve this.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Emails are serviced by Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Our Privacy Policy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Thanks for signing up!</h2>\n        <p class="ctct-form-text">You can unsubscribe at any time using the Unsubscribe link at the bottom of every email.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Email</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">First Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Last Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Phone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Country</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Street</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">City</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">State/Province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postal Code</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Company</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Job Title</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Birthday</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversary</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Email Lists</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Sorry, we could not complete your sign-up. Please contact us to resolve this.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Emails are serviced by Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Our Privacy Policy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Thanks for signing up!</h2>\n            <p class="ctct-form-text">You can unsubscribe at any time using the Unsubscribe link at the bottom of every email.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Email</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">First Name</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Last Name</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Phone</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Country</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Street</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">City</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">State/Province</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postal Code</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Company</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Job Title</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Birthday</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversary</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Email Lists</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Sorry, we could not complete your sign-up. Please contact us to resolve this.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Emails are serviced by Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Our Privacy Policy.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Thanks for signing up!</h2>\n        <p class="ctct-form-text">You can unsubscribe at any time using the Unsubscribe link at the bottom of every email.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Email</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">First Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Last Name</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Phone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Country</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Street</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">City</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">State/Province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postal Code</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Company</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Job Title</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Birthday</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversary</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Email Lists</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Sorry, we could not complete your sign-up. Please contact us to resolve this.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Emails are serviced by Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Our Privacy Policy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/en_US/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar la suscripción en cualquier momento si pulsa el enlace para cancelar la suscripción situado al final de cada correo electrónico.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellidos</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo laboral</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar el registro. Póngase en contacto con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Los mensajes de correo electrónico son un servicio de Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar la suscripción en cualquier momento si pulsa el enlace para cancelar la suscripción situado al final de cada correo electrónico.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellidos</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo laboral</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar el registro. Póngase en contacto con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Los mensajes de correo electrónico son un servicio de Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n            <p class="ctct-form-text">Puede cancelar la suscripción en cualquier momento si pulsa el enlace para cancelar la suscripción situado al final de cada correo electrónico.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellidos</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Teléfono</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/provincia</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo laboral</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar el registro. Póngase en contacto con nosotros para solucionarlo.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Los mensajes de correo electrónico son un servicio de Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar la suscripción en cualquier momento si pulsa el enlace para cancelar la suscripción situado al final de cada correo electrónico.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellidos</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo laboral</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar el registro. Póngase en contacto con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Los mensajes de correo electrónico son un servicio de Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar su suscripción en cualquier momento a través del enlace Cancelar suscripción al final de cada correo electrónico.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellido</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Número de teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/una provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Puesto</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar su inscripción. Comuníquese con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Constant Contact se ocupa de los correos electrónicos.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar su suscripción en cualquier momento a través del enlace Cancelar suscripción al final de cada correo electrónico.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellido</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Número de teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/una provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Puesto</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar su inscripción. Comuníquese con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Constant Contact se ocupa de los correos electrónicos.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n            <p class="ctct-form-text">Puede cancelar su suscripción en cualquier momento a través del enlace Cancelar suscripción al final de cada correo electrónico.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellido</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Número de teléfono</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/una provincia</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Puesto</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar su inscripción. Comuníquese con nosotros para solucionarlo.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Constant Contact se ocupa de los correos electrónicos.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">¡Gracias por suscribirse!</h2>\n        <p class="ctct-form-text">Puede cancelar su suscripción en cualquier momento a través del enlace Cancelar suscripción al final de cada correo electrónico.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Dirección de correo electrónico</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nombre</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Apellido</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Número de teléfono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Calle</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ciudad</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleccione un estado/una provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Código postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Puesto</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cumpleaños</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de correo electrónico</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Lo sentimos, no hemos podido completar su inscripción. Comuníquese con nosotros para solucionarlo.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Constant Contact se ocupa de los correos electrónicos.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nuestra Política de Privacidad.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/es_CO/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Merci pour votre inscription !</h2>\n        <p class="ctct-form-text">Vous pouvez vous désabonner à tout moment en utilisant le lien Résilier l’abonnement au bas de chaque courrier électronique.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Adresse e-mail</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Prénom</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nom de famille</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numéro de téléphone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un pays</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rue</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ville</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un état/une province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Code postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Entreprise</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Titre du poste</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listes électroniques</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Désolé, nous n&#39;avons pas pu compléter votre inscription. Veuillez nous contacter pour résoudre ce problème.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Les courriels sont traités par Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Notre Politique de Confidentialité.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Merci pour votre inscription !</h2>\n        <p class="ctct-form-text">Vous pouvez vous désabonner à tout moment en utilisant le lien Résilier l’abonnement au bas de chaque courrier électronique.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Adresse e-mail</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Prénom</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nom de famille</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numéro de téléphone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un pays</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rue</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ville</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un état/une province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Code postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Entreprise</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Titre du poste</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listes électroniques</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Désolé, nous n&#39;avons pas pu compléter votre inscription. Veuillez nous contacter pour résoudre ce problème.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Les courriels sont traités par Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Notre Politique de Confidentialité.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Merci pour votre inscription !</h2>\n            <p class="ctct-form-text">Vous pouvez vous désabonner à tout moment en utilisant le lien Résilier l’abonnement au bas de chaque courrier électronique.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Adresse e-mail</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Prénom</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nom de famille</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numéro de téléphone</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un pays</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rue</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ville</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un état/une province</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Code postal</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Entreprise</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Titre du poste</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listes électroniques</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Désolé, nous n&#39;avons pas pu compléter votre inscription. Veuillez nous contacter pour résoudre ce problème.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Les courriels sont traités par Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Notre Politique de Confidentialité.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Merci pour votre inscription !</h2>\n        <p class="ctct-form-text">Vous pouvez vous désabonner à tout moment en utilisant le lien Résilier l’abonnement au bas de chaque courrier électronique.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Adresse e-mail</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Prénom</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nom de famille</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numéro de téléphone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un pays</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rue</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ville</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sélectionnez un état/une province</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Code postal</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Entreprise</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Titre du poste</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversaire</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listes électroniques</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Désolé, nous n&#39;avons pas pu compléter votre inscription. Veuillez nous contacter pour résoudre ce problème.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Les courriels sont traités par Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Notre Politique de Confidentialité.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/fr/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Grazie per aver effettuato l\'iscrizione!</h2>\n        <p class="ctct-form-text">È possibile disdire l\'iscrizione in qualsiasi momento utilizzando l\'apposito link alla fine di ogni messaggio.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Indirizzo email</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cognome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numero di telefono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona un paese</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Via</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Località</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona stato/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CAP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Azienda</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Qualifica</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Compleanno</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Elenchi email</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Spiacenti, non è stato possibile completare l\'iscrizione. Contattaci per risolvere il problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">I messaggi email sono gestiti da Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">La Nostra Politica sulla Privacy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Grazie per aver effettuato l\'iscrizione!</h2>\n        <p class="ctct-form-text">È possibile disdire l\'iscrizione in qualsiasi momento utilizzando l\'apposito link alla fine di ogni messaggio.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Indirizzo email</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cognome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numero di telefono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona un paese</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Via</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Località</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona stato/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CAP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Azienda</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Qualifica</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Compleanno</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Elenchi email</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Spiacenti, non è stato possibile completare l\'iscrizione. Contattaci per risolvere il problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">I messaggi email sono gestiti da Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">La Nostra Politica sulla Privacy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Grazie per aver effettuato l\'iscrizione!</h2>\n            <p class="ctct-form-text">È possibile disdire l\'iscrizione in qualsiasi momento utilizzando l\'apposito link alla fine di ogni messaggio.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Indirizzo email</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cognome</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numero di telefono</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona un paese</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Via</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Località</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona stato/provincia</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CAP</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Azienda</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Qualifica</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Compleanno</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversario</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Elenchi email</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Spiacenti, non è stato possibile completare l\'iscrizione. Contattaci per risolvere il problema.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">I messaggi email sono gestiti da Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">La Nostra Politica sulla Privacy.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Grazie per aver effettuato l\'iscrizione!</h2>\n        <p class="ctct-form-text">È possibile disdire l\'iscrizione in qualsiasi momento utilizzando l\'apposito link alla fine di ogni messaggio.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Indirizzo email</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cognome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Numero di telefono</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona un paese</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Via</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Località</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Seleziona stato/provincia</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CAP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Azienda</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Qualifica</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Compleanno</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Anniversario</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Elenchi email</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Spiacenti, non è stato possibile completare l\'iscrizione. Contattaci per risolvere il problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">I messaggi email sono gestiti da Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">La Nostra Politica sulla Privacy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/it/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Bedankt voor uw aanmelding</h2>\n        <p class="ctct-form-text">U kunt u altijd afmelden door op de link Afmelden te klikken die onder aan elke e-mail te vinden is.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mailadres</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Voornaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Achternaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefoonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Adres</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Plaats</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een provincie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postcode</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bedrijf</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Functie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Verjaardag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubileum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-maillijsten</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">We hebben uw aanmelding helaas niet kunnen voltooien. Neem contact met ons op om dit op te lossen.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails worden verzorgd door Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Ons Privacybeleid.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Bedankt voor uw aanmelding</h2>\n        <p class="ctct-form-text">U kunt u altijd afmelden door op de link Afmelden te klikken die onder aan elke e-mail te vinden is.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mailadres</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Voornaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Achternaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefoonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Adres</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Plaats</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een provincie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postcode</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bedrijf</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Functie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Verjaardag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubileum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-maillijsten</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">We hebben uw aanmelding helaas niet kunnen voltooien. Neem contact met ons op om dit op te lossen.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails worden verzorgd door Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Ons Privacybeleid.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Bedankt voor uw aanmelding</h2>\n            <p class="ctct-form-text">U kunt u altijd afmelden door op de link Afmelden te klikken die onder aan elke e-mail te vinden is.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mailadres</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Voornaam</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Achternaam</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefoonnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een land</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Adres</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Plaats</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een provincie</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postcode</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bedrijf</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Functie</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Verjaardag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubileum</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-maillijsten</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">We hebben uw aanmelding helaas niet kunnen voltooien. Neem contact met ons op om dit op te lossen.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails worden verzorgd door Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Ons Privacybeleid.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Bedankt voor uw aanmelding</h2>\n        <p class="ctct-form-text">U kunt u altijd afmelden door op de link Afmelden te klikken die onder aan elke e-mail te vinden is.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-mailadres</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Voornaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Achternaam</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefoonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Adres</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Plaats</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecteer een provincie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postcode</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bedrijf</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Functie</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Verjaardag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Jubileum</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-maillijsten</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">We hebben uw aanmelding helaas niet kunnen voltooien. Neem contact met ons op om dit op te lossen.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-mails worden verzorgd door Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Ons Privacybeleid.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/nl/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Takk for at du registrerte deg!</h2>\n        <p class="ctct-form-text">Du kan avslutte abonnementet når som helst ved å klikke på linken Avslutt abonnement nederst i hver e-postmelding.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Etternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefon</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gateadr</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Poststed</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg delstat/region</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Virksomhet</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stilling</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bryllupsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Beklager, vi kunne ikke fullføre registreringen. Kontakt oss for å få løst dette.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-poster betjenes av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Personvernpolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Takk for at du registrerte deg!</h2>\n        <p class="ctct-form-text">Du kan avslutte abonnementet når som helst ved å klikke på linken Avslutt abonnement nederst i hver e-postmelding.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Etternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefon</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gateadr</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Poststed</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg delstat/region</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Virksomhet</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stilling</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bryllupsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Beklager, vi kunne ikke fullføre registreringen. Kontakt oss for å få løst dette.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-poster betjenes av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Personvernpolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Takk for at du registrerte deg!</h2>\n            <p class="ctct-form-text">Du kan avslutte abonnementet når som helst ved å klikke på linken Avslutt abonnement nederst i hver e-postmelding.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadresse</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Etternavn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefon</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg land</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gateadr</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Poststed</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg delstat/region</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Virksomhet</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stilling</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bryllupsdag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlister</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Beklager, vi kunne ikke fullføre registreringen. Kontakt oss for å få løst dette.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-poster betjenes av Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Personvernpolicy.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Takk for at du registrerte deg!</h2>\n        <p class="ctct-form-text">Du kan avslutte abonnementet når som helst ved å klikke på linken Avslutt abonnement nederst i hver e-postmelding.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadresse</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fornavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Etternavn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefon</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gateadr</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Poststed</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Velg delstat/region</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Virksomhet</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Stilling</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Fødselsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Bryllupsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlister</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Beklager, vi kunne ikke fullføre registreringen. Kontakt oss for å få løst dette.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-poster betjenes av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Personvernpolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/no/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Obrigado por se inscrever!</h2>\n        <p class="ctct-form-text">Você pode cancelar a sua inscrição a qualquer momento pelo link Unsubscribe (Cancelar inscrição) encontrado na parte inferior de cada e-mail.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Endereço de e-mail</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sobrenome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rua</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cidade</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um estado/província</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CEP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Data de nascimento</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversário</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de e-mail</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Desculpe, não foi possível concluir sua inscrição. Entre em contato conosco para resolver esse problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Os e-mails são processados pela Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nossa Política de Privacidade.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Obrigado por se inscrever!</h2>\n        <p class="ctct-form-text">Você pode cancelar a sua inscrição a qualquer momento pelo link Unsubscribe (Cancelar inscrição) encontrado na parte inferior de cada e-mail.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Endereço de e-mail</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sobrenome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rua</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cidade</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um estado/província</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CEP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Data de nascimento</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversário</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de e-mail</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Desculpe, não foi possível concluir sua inscrição. Entre em contato conosco para resolver esse problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Os e-mails são processados pela Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nossa Política de Privacidade.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Obrigado por se inscrever!</h2>\n            <p class="ctct-form-text">Você pode cancelar a sua inscrição a qualquer momento pelo link Unsubscribe (Cancelar inscrição) encontrado na parte inferior de cada e-mail.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Endereço de e-mail</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sobrenome</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefone</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um país</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rua</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cidade</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um estado/província</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CEP</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Data de nascimento</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversário</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de e-mail</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Desculpe, não foi possível concluir sua inscrição. Entre em contato conosco para resolver esse problema.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Os e-mails são processados pela Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nossa Política de Privacidade.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Obrigado por se inscrever!</h2>\n        <p class="ctct-form-text">Você pode cancelar a sua inscrição a qualquer momento pelo link Unsubscribe (Cancelar inscrição) encontrado na parte inferior de cada e-mail.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">Endereço de e-mail</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Nome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Sobrenome</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefone</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um país</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Rua</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cidade</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Selecione um estado/província</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">CEP</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Empresa</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Cargo</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Data de nascimento</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Aniversário</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">Listas de e-mail</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Desculpe, não foi possível concluir sua inscrição. Entre em contato conosco para resolver esse problema.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">Os e-mails são processados pela Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Nossa Política de Privacidade.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/pt/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/banner_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tack för att du anmäler dig!</h2>\n        <p class="ctct-form-text">Du kan avregistrera dig när som helst med hjälp av avregistrera-länken längst ned i varje e-postmeddelande.</p>\n    </div>\n    <button type="button" class="ctct-banner-close js-banner-close" title="Close">\n        <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n    </button>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n        \n        <div class="ctct-email-field-wrapper">\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field ctct-email-field-non-empty-title-description" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadress</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n        </div>\n        \n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Förnamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gata</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ort</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett län</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Företag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Befattning</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Födelsedag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <div class="ctct-form-lists-wrapper">\n\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlistor</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-form-lists">' +
__e( data.buttonText ) +
'</button>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (data.list_ids.length <= 1) { ;
__p += '\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-inline ctct-submit-inline-default ctct-submit-inline-visible">' +
__e( data.buttonText ) +
'</button>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi kunde tyvärr inte fullfölja registreringen av din anmälan. Kontakta oss för att lösa detta.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-postmeddelanden handhas av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Integritetspolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button ctct-submit-vertical">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/banner_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-banner-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-banner-inner ctct-banner-' +
__e( data.position ) +
' ctct-banner-top">\n      <div class="ctct-banner-content">\n        <div class="ctct-form-container ctct-form-banner form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-banner-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/flyout_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tack för att du anmäler dig!</h2>\n        <p class="ctct-form-text">Du kan avregistrera dig när som helst med hjälp av avregistrera-länken längst ned i varje e-postmeddelande.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadress</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Förnamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gata</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ort</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett län</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Företag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Befattning</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Födelsedag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlistor</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi kunde tyvärr inte fullfölja registreringen av din anmälan. Kontakta oss för att lösa detta.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-postmeddelanden handhas av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Integritetspolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/flyout_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-flyout-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-flyout-inner ctct-flyout-' +
__e( data.position ) +
'">\n      <div class="ctct-flyout-content">\n          <button type="button" class="ctct-flyout-close js-flyout-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-flyout form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-flyout-trigger"></div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/inline_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-container ctct-form-embed form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-form-defaults" data-qe-id="form-background">\n        <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n            <h2 class="ctct-form-header">Tack för att du anmäler dig!</h2>\n            <p class="ctct-form-text">Du kan avregistrera dig när som helst med hjälp av avregistrera-länken längst ned i varje e-postmeddelande.</p>\n        </div>\n        <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n            ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n            <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n            ';
 } ;
__p += '\n            ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n            <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n            ';
 } ;
__p += '\n\n            <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadress</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n            </div>\n\n            ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n            <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Förnamn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n            <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternamn</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n            <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n            <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett land</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n                <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                    <option value="">--</option>\n                    ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                        ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n            <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gata</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n            <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ort</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n            <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett län</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n                <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n                <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n                </select>\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n            <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n            <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Företag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n            <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Befattning</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n            <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Födelsedag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n                <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n            ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n            <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n                <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n\n        ';
 _.each(data._customFields, function(customField) { ;
__p += '\n            ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n            <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span>\n                <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n        ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n            ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n            </div>\n            ';
 } ;
__p += '\n            ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n            <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n                <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.name ) +
'" style="display: none;"></div>\n                <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n                <span class="ctct-form-date-slash">/</span> \n                <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.name ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n            </div>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n\n            ';
 if (data.list_ids.length > 1) { ;
__p += '\n            <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n                <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlistor</legend>\n                <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n                ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                    <div class="ctct-form-listitem">\n                        <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                        <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                    </div>\n                ';
 }); ;
__p += '\n            </fieldset>\n            ';
 } ;
__p += '\n\n            <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Vi kunde tyvärr inte fullfölja registreringen av din anmälan. Kontakta oss för att lösa detta.</p>\n            </div>\n            <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n                <p class="ctct-form-errorMessage">Operation timed out, please try again.</p>\n            </div>\n\n            <div id="gdpr_text">\n                <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                    ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                    <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-postmeddelanden handhas av Constant Contact.</a>\n                    ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                        <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Integritetspolicy.</a>\n                    ';
 } ;
__p += '\n                </p>\n            </div>\n\n            <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n\n            ';
 if (data.recaptchaKey) { ;
__p += '\n            <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible">\n            </div>\n            ';
 } ;
__p += '\n        </form>\n        <p class="ctct-form-footer">\n            ';
 if (!data.hideBranding) { ;
__p += '\n            <span data-qe-id="form-branding">\n                <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel="nofollow" target="_blank" rel=”noopener noreferrer” class="ctct-form-footer-link">\n                    <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n                </a>\n            </span>\n            ';
 } ;
__p += '\n        </p>\n    </div>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/popup_form.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-form-defaults" data-qe-id="form-background">\n    <div id="success_message_' +
__e( data.form_index ) +
'" class="ctct-form-success" style="display:none;" data-qe-id="success-message">\n        <h2 class="ctct-form-header">Tack för att du anmäler dig!</h2>\n        <p class="ctct-form-text">Du kan avregistrera dig när som helst med hjälp av avregistrera-länken längst ned i varje e-postmeddelande.</p>\n    </div>\n    <form class="ctct-form-custom" id="ctct_form_' +
__e( data.form_index ) +
'" autocomplete="on" data-qe-id="form-data">\n        <div class="ctct-form-logo"></div>\n\n        ';
 if(!_.isEmpty(data.titleText)) { ;
__p += '\n        <h2 data-qe-id="form-title" class="ctct-form-header">' +
__e( data.titleText ) +
'</h2>\n        ';
 } ;
__p += '\n        ';
 if(!_.isEmpty(data.descriptionText)) { ;
__p += '\n        <p data-qe-id="form-description" class="ctct-form-text">' +
__e( data.descriptionText ) +
'</p>\n        ';
 } ;
__p += '\n\n        <div id="email_address_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-email" id="email_address_label_' +
__e( data.form_index ) +
'" for="email_address_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-required">E-postadress</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-email" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-email" id="email_address_' +
__e( data.form_index ) +
'" type="email" name="email_address" value="" maxlength="80">\n        </div>\n\n        ';
 if(_.contains(data.contact_fields, 'first_name')) { ;
__p += '\n        <div id="first_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-first-name" id="first_name_label_' +
__e( data.form_index ) +
'" for="first_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Förnamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-first-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-first-name" id="first_name_' +
__e( data.form_index ) +
'" type="text" name="first_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'last_name')) { ;
__p += '\n        <div id="last_name_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-last-name" id="last_name_label_' +
__e( data.form_index ) +
'" for="last_name_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Efternamn</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-last-name" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-last-name" id="last_name_' +
__e( data.form_index ) +
'" type="text" name="last_name" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'phone')) { ;
__p += '\n        <div id="phone_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-phone" id="phone_label_' +
__e( data.form_index ) +
'" for="phone_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Telefonnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-phone" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-phone" id="phone_' +
__e( data.form_index ) +
'" type="tel" name="phone" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'country')) { ;
__p += '\n        <div id="country_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-country" id="country_label_' +
__e( data.form_index ) +
'" for="country_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett land</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-country" style="display: none;"></div>\n            <select class="ctct-select-element" data-qe-id="form-select-country-' +
__e( data.form_index ) +
'" id="country_' +
__e( data.form_index ) +
'" name="country">\n                <option value="">--</option>\n                ';
 _.each(data._defaults.countries, function(country) { ;
__p += '\n                    ';
 if (country.countryCode === data.selectedCountry) { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'" selected>' +
__e( country.display ) +
'</option>\n                    ';
 } else { ;
__p += '\n                        <option value="' +
__e( country.countryCode ) +
'">' +
__e( country.display ) +
'</option>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'street')) { ;
__p += '\n        <div id="street_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-street" id="street_label_' +
__e( data.form_index ) +
'" for="street_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Gata</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-street" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-street" id="street_' +
__e( data.form_index ) +
'" type="text" name="street" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'city')) { ;
__p += '\n        <div id="city_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-city" id="city_label_' +
__e( data.form_index ) +
'" for="city_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Ort</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-city" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-city" id="city_' +
__e( data.form_index ) +
'" type="text" name="city" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'state')) { ;
__p += '\n        <div id="state_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-state" id="state_label_' +
__e( data.form_index ) +
'" for="state_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Välj ett län</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-state" style="display: none;"></div>\n            <input data-qe-id="form-input-state" class="state_' +
__e( data.form_index ) +
' ctct-form-element" id="state_' +
__e( data.form_index ) +
'" type="text" name="state" value="" maxlength="50">\n            <select data-qe-id="form-select-state-' +
__e( data.form_index ) +
'" class="state_' +
__e( data.form_index ) +
' ctct-select-element" id="state_' +
__e( data.form_index ) +
'" style="display: none;" name="state">\n            </select>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'postal_code')) { ;
__p += '\n        <div id="postal_code_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-postal-code" id="postal_code_label_' +
__e( data.form_index ) +
'" for="postal_code_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Postnummer</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-postal-code" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-postal-code" id="postal_code_' +
__e( data.form_index ) +
'" type="text" name="postal_code" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'company')) { ;
__p += '\n        <div id="company_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-company" id="company_label_' +
__e( data.form_index ) +
'" for="company_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Företag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-company" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-company" id="company_' +
__e( data.form_index ) +
'" type="text" name="company" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'job_title')) { ;
__p += '\n        <div id="job_title_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-title" id="job_title_label_' +
__e( data.form_index ) +
'" for="job_title_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Befattning</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-job-title" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-title" id="job_title_' +
__e( data.form_index ) +
'" type="text" name="job_title" value="" maxlength="50">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'birthday')) { ;
__p += '\n        <div id="birthday_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-birthday" id="birthday_label_' +
__e( data.form_index ) +
'" for="birthday_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Födelsedag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-birthday" style="display: none;"></div>\n            <input data-qe-id="form-input-birthday-month" id="birthday_month_' +
__e( data.form_index ) +
'" type="number" name="birthday_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" min="1" max="12" maxlength="2" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-birthday-day" id="birthday_day_' +
__e( data.form_index ) +
'" type="number" name="birthday_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('birthday_aria_label_day') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if(_.contains(data.contact_fields, 'anniversary')) { ;
__p += '\n        <div id="anniversary_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-anniversary" id="anniversary_label_' +
__e( data.form_index ) +
'" for="anniversary_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">Årsdag</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-anniversary" style="display: none;"></div>\n            <input data-qe-id="form-input-anniversary-month" id="anniversary_month_' +
__e( data.form_index ) +
'" type="number" name="anniversary_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-day" id="anniversary_day_' +
__e( data.form_index ) +
'" type="number" name="anniversary_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-anniversary-year" id="anniversary_year_' +
__e( data.form_index ) +
'" type="number" name="anniversary_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="' +
__e( data.max_anniversary_year ) +
'" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('anniversary_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n\n    ';
 _.each(data._customFields, function(customField) { ;
__p += '\n        ';
 if(customField.type === 'custom_field_string') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'" value="" maxlength="255">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(customField.type === 'custom_field_date') { ;
__p += '\n        <div id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-custom-field-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( customField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( customField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-custom-field-month-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-day-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'"> <span class="ctct-form-date-slash">/</span> <input data-qe-id="form-input-custom-field-year-' +
__e( customField.label ) +
'" id="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="' +
__e( customField.type ) +
'_' +
__e( customField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="9999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n    ';
 _.each(data._resciCustomFields, function(resciCustomField) { ;
__p += '\n        ';
 if(resciCustomField.type === 'VARCHAR') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-label ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input class="ctct-form-element" data-qe-id="form-input-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_' +
__e( data.form_index ) +
'" type="text" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'" value="" maxlength="254">\n        </div>\n        ';
 } ;
__p += '\n        ';
 if(resciCustomField.type === 'DATETIME' || resciCustomField.type === 'DATE') { ;
__p += '\n        <div id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_field_' +
__e( data.form_index ) +
'" class="ctct-form-field" >\n            <label data-qe-id="form-label-resci-custom-field-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_label_' +
__e( data.form_index ) +
'" for="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" class="ctct-form-label ">' +
__e( resciCustomField.label ) +
'</label>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-resci-custom-field-' +
__e( resciCustomField.label ) +
'" style="display: none;"></div>\n            <input data-qe-id="form-input-resci-custom-field-month-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_month" value="" placeholder="MM" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="12" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_month') ) +
'"> \n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-day-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_day" value="" placeholder="DD" class="ctct-form-element ctct-form-date-field-2" maxlength="2" min="1" max="31" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_day') ) +
'">\n            <span class="ctct-form-date-slash">/</span> \n            <input data-qe-id="form-input-resci-custom-field-year-' +
__e( resciCustomField.label ) +
'" id="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year_' +
__e( data.form_index ) +
'" type="number" name="resci_' +
__e( resciCustomField.type ) +
'_' +
__e( resciCustomField.name ) +
'_year" value="" placeholder="YYYY" class="ctct-form-element ctct-form-date-field-4" maxlength="4" min="1900" max="2999" aria-label="' +
__e( SignUpFormWidget.Helpers.i18n.translate('date_aria_label_year') ) +
'">\n        </div>\n        ';
 } ;
__p += '\n    ';
 }); ;
__p += '\n\n        ';
 if (data.list_ids.length > 1) { ;
__p += '\n        <fieldset id="list_memberships_field_' +
__e( data.form_index ) +
'" class="ctct-form-lists">\n            <legend id="list_memberships_label_' +
__e( data.form_index ) +
'" class="ctct-form-required ctct-form-lists-legend">E-postlistor</legend>\n            <div class="ctct-form-errorMessage" data-qe-id="form-error-list_memberships" style="display: none;"></div>\n            ';
 _.each(data._emailLists, function(emailList, index) { ;
__p += '\n                <div class="ctct-form-listitem">\n                    <input class="ctct-form-checkbox" data-qe-id="form-input-list-' +
__e( emailList.label ) +
'" id="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" type="checkbox" name="emailList" value="' +
__e( emailList.id ) +
'">\n                    <label data-qe-id="form-label-list-' +
__e( emailList.label ) +
'" for="email_list_' +
__e( index ) +
'_' +
__e( data.form_index ) +
'" class="ctct-form-listname ctct-form-checkbox-label">' +
__e( emailList.label ) +
'</label>\n                </div>\n            ';
 }); ;
__p += '\n        </fieldset>\n        ';
 } ;
__p += '\n\n        <div id="error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Vi kunde tyvärr inte fullfölja registreringen av din anmälan. Kontakta oss för att lösa detta.</div>\n        </div>\n        <div id="network_error_message_' +
__e( data.form_index ) +
'" class="ctct-form-error" style="display:none;">\n            <div class="ctct-form-errorMessage">Operation timed out, please try again.</div>\n        </div>\n        <div id="gdpr_text">\n            <p class="ctct-gdpr-text" data-qe-id="form-gdpr-text">\n                ' +
__e( SignUpFormWidget.Helpers.i18n.translate('gdpr_txt', SignUpFormWidget.Helpers.i18n.getFinePrintTextFromActiveLanguage(data._defaults.finePrintText), data.company_info) ) +
'\n                <a href="' +
__e( data._defaults.serviceUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-service-link" class="ctct-form-footer-link">E-postmeddelanden handhas av Constant Contact.</a>\n                ';
 if (data._defaults.customPrivacyUrl) { ;
__p += '\n                    <a href="' +
__e( data._defaults.customPrivacyUrl ) +
'" target="_blank" rel=”noopener noreferrer” data-qe-id="form-privacy-link" class="ctct-form-footer-privacy-link">Vår Integritetspolicy.</a>\n                ';
 } ;
__p += '\n            </p>\n        </div>\n        <button data-qe-id="form-button" type="submit" class="ctct-form-button">' +
__e( data.buttonText ) +
'</button>\n    </form>\n    <p class="ctct-form-footer">\n        ';
 if(!data.hideBranding) { ;
__p += '\n        <span data-qe-id="form-branding">\n            <a href="http://www.constantcontact.com/index.jsp?cc=forms_popup" rel=”noopener noreferrer” target="_blank" class="ctct-form-footer-link">\n                <img src="https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020" alt="" class="ctct-form-footer-img"/>\n            </a>\n        </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';
return __p
};

SignUpFormWidget["JST"]["dist/templates/sv/popup_wrapper.html"] = function(data) {
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="ctct-popup-wrapper form_' +
__e( data.form_index ) +
'">\n    <div class="ctct-popup-overlay"></div>\n    <div class="ctct-popup-inner">\n      <div class="ctct-popup-content">\n          <button type="button" class="ctct-popup-close js-popup-close" title="Close">\n              <img src="https://static.ctctcdn.com/contacts/images/signup-tools/ctct-close-x.svg" class="ctct-X" />\n          </button>\n        <div class="ctct-form-container ctct-form-popup form_' +
__e( data.form_index ) +
'"></div>\n      </div>\n    </div>\n\n    ';
 if (data.recaptchaKey) { ;
__p += '\n    <div class="g-recaptcha" id="ctct_recaptcha_' +
__e( data.form_index ) +
'" data-sitekey="' +
__e( data.recaptchaKey ) +
'" data-size="invisible"></div>\n    ';
 } ;
__p += '\n</div>\n<div class="ctct-popup-trigger"></div>\n';
return __p
};