// set log levels for stuff and things
if (SignUpFormWidget.debugMode) {
    SignUpFormWidget.Helpers.Logger.setDebugMode(true);
}
else {
    SignUpFormWidget.Helpers.Logger.setDebugMode(false);
}

SignUpFormWidget.Handlers.Style.loadBaseStyles();

/**
 * Handles rendering of all forms by type
 *
 * Sets up submit handler
 */
SignUpFormWidget.main = () => {
    const _putFormInSubmittedState = (form) => {
        if(form.type === 'POPUP') {
            _.each(SignUpFormWidget.triggerEventTypes, (eventType) => {
                SignUpFormWidget.Helpers.PopupFrequencyController.setFormLastSeenTime(form, eventType);
            });
        } else if(form.type === 'FLYOUT') {
            _.each(SignUpFormWidget.triggerEventTypes, (eventType) => {
                SignUpFormWidget.Helpers.FlyoutFrequencyController.setFormLastSeenTime(form, eventType);
            });
        } else if(form.type === 'BAR') {
            _.each(SignUpFormWidget.triggerEventTypes, (eventType) => {
                SignUpFormWidget.Helpers.BannerFrequencyController.setFormLastSeenTime(form, eventType);
            });
        } 

    };

    /**
     * Handles form input collection, input validation, submission, and response handling.
     *
     * @param {Object} $elem the rendered form element
     * @param {Object} form the form config object
     * @param {Function} [submitCb] a callback after submit completes
     * @returns {undefined}
     * @private
     */
    const _handleClickSubmitForm = ($elem, form, submitCb = () => {}) => {
        const contactData = SignUpFormWidget.Helpers.Collector.getFormInputs(form);
        if (!_.isEmpty(contactData) && $elem.find('.ctct-form-button').attr('disabled') !== 'disabled') {
            $elem.find('.ctct-form-button').attr('disabled', 'disabled');

            if (SignUpFormWidget.Handlers.Config.getRecaptchaKey() === null) {
                SignUpFormWidget.Handlers.submitForm(null, $elem, form).done(() => {
                    submitCb(form);
                });
            }
            else {
                const recaptchaId = SignUpFormWidget.RenderedCaptchas[`captcha_${form.form_index}`];

                // reset recaptcha on every submit so its ready to run
                grecaptcha.reset(recaptchaId);

                // shows the challenge
                grecaptcha.execute(recaptchaId);
            }
        }
    };

    const _renderInlineForm = (form, companyInfo) => {
        let $elem = $(`.ctct-inline-form[data-form-id="${form.form_id}"]`);
        if ($elem.length === 0) {
            SignUpFormWidget.Helpers.Logger.warn(`Div for inline form "${form.name}" is missing. Was inline code installed?`);
        } else {
            // should it show on this device type?
            if (SignUpFormWidget.Helpers.Devices.showOnThisDevice(form)) {
                SignUpFormWidget.Render.inlineForm($elem, form,  companyInfo).done(() => {
                    if (SignUpFormWidget.Handlers.Config.getRecaptchaKey() !== null) {
                        // Prepares the recaptcha container. Last param inherits sitekey from container DOM node.
                        try {
                            const $recaptchaElem = $elem.find(`#ctct_recaptcha_${form.form_index}`).get(0);
                            const recaptchaId = grecaptcha.render($recaptchaElem, {
                                'callback'(token) {
                                    SignUpFormWidget.Handlers.submitForm(token, $elem, form);
                                },
                            }, true);
                            // store reference to ID for submit handler
                            SignUpFormWidget.RenderedCaptchas[`captcha_${form.form_index}`] = recaptchaId;
                        }
                        catch (e) {
                            SignUpFormWidget.Helpers.Logger.error(`Error registering recaptcha for form "${form.name}"`, e);
                        }
                    }

                    const $submitBtn = $elem.find('.ctct-form-button');
                    $submitBtn.on('click', {form}, (e) => {
                        _handleClickSubmitForm($elem, e.data.form);
                    });
                    // make default submit action act as a submit
                    $elem.on('submit', (e) => {
                        e.preventDefault();
                        $submitBtn.click();
                    });

                }).fail((msg, err) => {
                    SignUpFormWidget.Helpers.Logger.error(`Error rendering form ${form.name}, please republish this form.`, err);
                });
            }
        }
    };

    const _renderPopupForm = (form, companyInfo) => {

        const timingOpts = form.config.timing;
        const popupEventNamespace = `ctctPopupForm.${form.form_id}`;

        // check for wrapper and re-use, just in case
        let $popupElem = $(`.ctct-popup-form[data-form-id="${form.form_id}"]`);

        const _destroyPopup = () => {
            $popupElem.off('submit');
            $popupElem.find('.ctct-popup-wrapper').ctctPopupForm('destroy');
            $(document).off(`mouseleave.${popupEventNamespace}`);
            $popupElem.find('.ctct-form-button').off(`click.${popupEventNamespace}`);
            $popupElem.find('.ctct-popup-trigger').hide();
        };

        if ($popupElem.length === 0) {
            $popupElem = $(`<div class="ctct-popup-form" data-form-id="${form.form_id}"></div>`);
            $('body').append($popupElem);
        }
        else if ($popupElem.find('.ctct-popup-wrapper').length > 0) {
            _destroyPopup();
        }

        SignUpFormWidget.Render.popupForm($popupElem, form, companyInfo).done(() => {
            let destroyOnClose = false;

            const _submitCb = () => {
                _putFormInSubmittedState(form);
                destroyOnClose = true;
            };

            const $popupWrapper = $popupElem.find('.ctct-popup-wrapper');
            // Prepares the recaptcha container. Last param inherits sitekey from container DOM node.
            if (SignUpFormWidget.Handlers.Config.getRecaptchaKey() !== null) {
                try {
                    const $recaptchaElem = $popupElem.find(`#ctct_recaptcha_${form.form_index}`).get(0);
                    const recaptchaId = grecaptcha.render($recaptchaElem, {
                        'callback'(token) {
                            SignUpFormWidget.Handlers.submitForm(token, $popupElem, form).done(_submitCb);
                        },
                    }, true);
                    // store reference to ID for submit handler
                    SignUpFormWidget.RenderedCaptchas[`captcha_${form.form_index}`] = recaptchaId;
                }
                catch (e) {
                    SignUpFormWidget.Helpers.Logger.error(`Error registering recaptcha for form "${form.name}"`, e);
                }
            }

            const $submitBtn = $popupElem.find('.ctct-form-button');
            $submitBtn.on(`click.${popupEventNamespace}`, {form}, (e) => {
                _handleClickSubmitForm($popupElem, e.data.form, _submitCb);
            });
            // make default submit action act as a submit
            $popupElem.on('submit', (e) => {
                e.preventDefault();
                $submitBtn.click();
            });

            let options = {
                namespace: form.form_index,
                formId: form.form_id,
                isEligible: (eventType) => {
                    return SignUpFormWidget.Helpers.PopupFrequencyController.formEligibleToBeShown(form, eventType);
                },
                closeCallback: (eventType) => {
                    SignUpFormWidget.Helpers.PopupFrequencyController.setFormLastSeenTime(form, eventType);
                    if (destroyOnClose) {
                        _destroyPopup();
                    }
                },
            };

            if (_.isObject(timingOpts)) {
                options.autoOpen = timingOpts.useDelay;
                options.timeDelay = timingOpts.delaySeconds * 1000;
            }
            $popupWrapper.ctctPopupForm(options);

            if (_.isObject(timingOpts) && timingOpts.useExitIntent) {
                $(document).on(`mouseleave.${popupEventNamespace}`, (e) => {
                    $popupWrapper.ctctPopupForm('show', 'exit', true);
                });
            }
        }).fail((msg, err) => {
            SignUpFormWidget.Helpers.Logger.error(`Error rendering form ${form.name}, please republish this form.`, err);
        });
    };

    const _renderFlyoutForm = (form, companyInfo) => {
        const timingOpts = form.config.timing;
        const flyoutEventNamespace = `ctctFlyoutForm.${form.form_id}`;

        // check for wrapper and re-use, just in case
        let $flyoutElem = $(`.ctct-flyout-form[data-form-id="${form.form_id}"]`);

        const _destroyFlyout = () => {
            $flyoutElem.off('submit');
            $flyoutElem.find('.ctct-flyout-wrapper').ctctFlyoutForm('destroy');
            $(document).off(`mouseleave.${flyoutEventNamespace}`);
            $flyoutElem.find('.ctct-form-button').off(`click.${flyoutEventNamespace}`);
            $flyoutElem.find('.ctct-flyout-trigger').hide();
        };

        if ($flyoutElem.length === 0) {
            $flyoutElem = $(`<div class="ctct-flyout-form" data-form-id="${form.form_id}"></div>`);
            $('body').append($flyoutElem);
        }
        else if ($flyoutElem.find('.ctct-flyout-wrapper').length > 0) {
            _destroyFlyout();
        }

        SignUpFormWidget.Render.flyoutForm($flyoutElem, form, companyInfo).done(() => {
            let destroyOnClose = false;

            const _submitCb = () => {
                _putFormInSubmittedState(form);
                destroyOnClose = true;
            };

            const $flyoutWrapper = $flyoutElem.find('.ctct-flyout-wrapper');
            // Prepares the recaptcha container. Last param inherits sitekey from container DOM node.
            if (SignUpFormWidget.Handlers.Config.getRecaptchaKey() !== null) {
                try {
                    const $recaptchaElem = $flyoutElem.find(`#ctct_recaptcha_${form.form_index}`).get(0);
                    const recaptchaId = grecaptcha.render($recaptchaElem, {
                        'callback'(token) {
                            SignUpFormWidget.Handlers.submitForm(token, $flyoutElem, form).done(_submitCb);
                        },
                    }, true);
                    // store reference to ID for submit handler
                    SignUpFormWidget.RenderedCaptchas[`captcha_${form.form_index}`] = recaptchaId;
                }
                catch (e) {
                    SignUpFormWidget.Helpers.Logger.error(`Error registering recaptcha for form "${form.name}"`, e);
                }
            }

            const $submitBtn = $flyoutElem.find('.ctct-form-button');
            $submitBtn.on(`click.${flyoutEventNamespace}`, {form}, (e) => {
                _handleClickSubmitForm($flyoutElem, e.data.form, _submitCb);
            });
            // make default submit action act as a submit
            $flyoutElem.on('submit', (e) => {
                e.preventDefault();
                $submitBtn.click();
            });

            let options = {
                namespace: form.form_index,
                formId: form.form_id,
                isEligible: (eventType) => {
                    return SignUpFormWidget.Helpers.FlyoutFrequencyController.formEligibleToBeShown(form, eventType);
                },
                closeCallback: (eventType) => {
                    SignUpFormWidget.Helpers.FlyoutFrequencyController.setFormLastSeenTime(form, eventType);
                    if (destroyOnClose) {
                        _destroyFlyout();
                    }
                },
                animationClass: form.config.flyout.animated && SignUpFormWidget.Helpers.FlyoutAnimationHelper.getAnimationClass(form.config.flyout.position)
            };

            if (_.isObject(timingOpts)) {
                options.autoOpen = timingOpts.useDelay;
                options.timeDelay = timingOpts.delaySeconds * 1000;
            }
            $flyoutWrapper.ctctFlyoutForm(options);

            if (_.isObject(timingOpts) && timingOpts.useExitIntent) {
                $(document).on(`mouseleave.${flyoutEventNamespace}`, (e) => {
                    $flyoutWrapper.ctctFlyoutForm('show', 'exit', true);
                });
            }
        }).fail((msg, err) => {
            SignUpFormWidget.Helpers.Logger.error(`Error rendering form ${form.name}, please republish this form.`, err);
        });
    };

    const _renderBannerForm = (form, companyInfo) => {
        const timingOpts = form.config.timing;
        const bannerEventNamespace = `ctctBannerForm.${form.form_id}`;

        // check for wrapper and re-use, just in case
        let $bannerElem = $(`.ctct-banner-form[data-form-id="${form.form_id}"]`);

        const _destroyBanner = () => {
            $bannerElem.off('submit');
            $bannerElem.find('.ctct-banner-wrapper').ctctBannerForm('destroy');
            $(document).off(`mouseleave.${bannerEventNamespace}`);
            $bannerElem.find('.ctct-form-button').off(`click.${bannerEventNamespace}`);
            $bannerElem.find('.ctct-banner-trigger').hide();
        };

        if ($bannerElem.length === 0) {
            $bannerElem = $(`<div class="ctct-banner-form" data-form-id="${form.form_id}"></div>`);
            $('body').append($bannerElem);
        }
        else if ($bannerElem.find('.ctct-banner-wrapper').length > 0) {
            _destroyBanner();
        }

        SignUpFormWidget.Render.barForm($bannerElem, form, companyInfo).done(() => {
            const bannerContactFieldsWarningLength = 1;
            const bannerEmailListsWarningLength = 2;
            let destroyOnClose = false;

            const _submitCb = () => {
                _putFormInSubmittedState(form);
                destroyOnClose = true;
            };
            const { position }  = form.config.banner;
            if(position === 'bottom' && $bannerElem.find('.ctct-banner-inner').hasClass('ctct-banner-top')) {
                $bannerElem.find('.ctct-banner-inner').removeClass('ctct-banner-top');
            }
            if(position === 'top' && $bannerElem.find('.ctct-banner-inner').hasClass('ctct-banner-bottom')) {
                $bannerElem.find('.ctct-banner-inner').removeClass('ctct-banner-bottom');
            }
            $bannerElem.find('.ctct-banner-inner').addClass(`ctct-banner-${position}`);


            // Change button placement if 1 contact field and no title and description.
            if(form.config.title.text === '' && form.config.description.text === '' && form.config.contactFields.length === bannerContactFieldsWarningLength && form.config.emailLists.length < bannerEmailListsWarningLength) {
                $bannerElem.find('.ctct-submit-inline-default').removeClass('ctct-submit-inline-visible');
                $bannerElem.find('.ctct-submit-inline-default').addClass('ctct-submit-inline-not-visible');
                SignUpFormWidget.Helpers.BannerHelper.renderEmailFieldEmptyTitleDescription(form);

            } else {
                $bannerElem.find('.ctct-submit-inline-default').removeClass('ctct-submit-inline-not-visible');
                $bannerElem.find('.ctct-submit-inline-default').addClass('ctct-submit-inline-visible');
                SignUpFormWidget.Helpers.BannerHelper.renderEmailField(form);
            }

            const $bannerWrapper = $bannerElem.find('.ctct-banner-wrapper');
            // Prepares the recaptcha container. Last param inherits sitekey from container DOM node.
            if (SignUpFormWidget.Handlers.Config.getRecaptchaKey() !== null) {
                try {
                    const $recaptchaElem = $bannerElem.find(`#ctct_recaptcha_${form.form_index}`).get(0);
                    const recaptchaId = grecaptcha.render($recaptchaElem, {
                        'callback'(token) {
                            SignUpFormWidget.Handlers.submitForm(token, $bannerElem, form).done(_submitCb);
                        },
                    }, true);
                    // store reference to ID for submit handler
                    SignUpFormWidget.RenderedCaptchas[`captcha_${form.form_index}`] = recaptchaId;
                }
                catch (e) {
                    SignUpFormWidget.Helpers.Logger.error(`Error registering recaptcha for form "${form.name}"`, e);
                }
            }

            const $submitBtn = $bannerElem.find('.ctct-form-button');
            $submitBtn.on(`click.${bannerEventNamespace}`, {form}, (e) => {
                _handleClickSubmitForm($bannerElem, e.data.form, _submitCb);
            });
            // make default submit action act as a submit
            $bannerElem.on('submit', (e) => {
                e.preventDefault();
                $submitBtn.click();
            });

            let options = {
                namespace: form.form_index,
                formId: form.form_id,
                formIndex: form.form_index,
                isEligible: (eventType) => {
                    return SignUpFormWidget.Helpers.BannerFrequencyController.formEligibleToBeShown(form, eventType);
                },
                closeCallback: (eventType) => {
                    SignUpFormWidget.Helpers.BannerFrequencyController.setFormLastSeenTime(form, eventType);
                    if (destroyOnClose) {
                        _destroyBanner();
                    }
                },
                animationClass: form.config.banner.animated && SignUpFormWidget.Helpers.BannerAnimationHelper.getAnimationClass(form.config.banner.position)
            };

            if (_.isObject(timingOpts)) {
                options.autoOpen = timingOpts.useDelay;
                options.timeDelay = timingOpts.delaySeconds * 1000;
            }
            $bannerWrapper.ctctBannerForm(options);

            if (_.isObject(timingOpts) && timingOpts.useExitIntent) {
                $(document).on(`mouseleave.${bannerEventNamespace}`, (e) => {
                    $bannerWrapper.ctctBannerForm('show', 'exit', true);
                });
            }
        }).fail((msg, err) => {
            SignUpFormWidget.Helpers.Logger.error(`Error rendering form ${form.name}, please republish this form.`, err);
        });
    };

    SignUpFormWidget.Handlers.Config.loadConfig().done((data) => {
        const forms = data.forms;
        const companyInfo = data.companyInfo;
        $.each(forms, (idx, form) => {
            switch (form.type) {
                case 'INLINE':
                    _renderInlineForm(form, companyInfo);
                    break;
                case 'FLYOUT':
                    if (SignUpFormWidget.Helpers.FlyoutFrequencyController.formEligibleToBeShown(form)) {
                        _renderFlyoutForm(form, companyInfo);
                        break;
                    }
                    break;
                case 'POPUP':
                    if (SignUpFormWidget.Helpers.PopupFrequencyController.formEligibleToBeShown(form)) {
                        _renderPopupForm(form, companyInfo);
                        break;
                    }
                    break;
                case 'BAR':
                    if (SignUpFormWidget.Helpers.BannerFrequencyController.formEligibleToBeShown(form)) {
                        _renderBannerForm(form, companyInfo);
                        break;
                    }
                    break;
                default:
                    SignUpFormWidget.Helpers.Logger.debug(`Unknown form type ${form.type}`);
            }
        });
    }).fail((error) => {
        SignUpFormWidget.Helpers.Logger.warn(error);
    });
};

if (!SignUpFormWidget.previewMode) {
    SignUpFormWidget.main();
}
