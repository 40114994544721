/** @namespace SignUpFormWidget */
let SignUpFormWidget = window.SignUpFormWidget || {};

SignUpFormWidget.triggerEventTypes = [ 'timer', 'exit', 'button' ];

SignUpFormWidget.previewMode = previewMode;
SignUpFormWidget.debugMode = ((() => {
    return $('[data-ctct-debug="true"]').length < 0;
})());

let _ctct_m = window._ctct_m || undefined;

/** @namespace SignUpFormWidget.Helpers */
SignUpFormWidget.Helpers = SignUpFormWidget.Helpers || {};
/** @namespace SignUpFormWidget.Validation */
SignUpFormWidget.Validation = SignUpFormWidget.Validation || {};
/** @namespace SignUpFormWidget.Errors */
SignUpFormWidget.Errors = SignUpFormWidget.Errors || {};
/** @namespace SignUpFormWidget.Handlers */
SignUpFormWidget.Handlers = SignUpFormWidget.Handlers || {};
/** @namespace SignUpFormWidget.Render */
SignUpFormWidget.Render = SignUpFormWidget.Render || {};

/** @private */
SignUpFormWidget._instances = SignUpFormWidget._instances || {};
/** @namespace SignUpFormWidget.Instance */
SignUpFormWidget.Instance = SignUpFormWidget.Instance || {};
/** @namespace SignUpFormWidget.Api */
SignUpFormWidget.Api = SignUpFormWidget.Api || {};
/**
 * @namespace SignUpFormWidget.RenderedCaptchas
 * Used to map captcha IDs to form indices
*/
SignUpFormWidget.RenderedCaptchas = SignUpFormWidget.RenderedCaptchas || {};

