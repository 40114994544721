SignUpFormWidget.Validation.Form = (function() {
    let api;

    function _buildErrors(field, codes, customFieldType, resciCustomFieldType) {
        let err = { };
        if (!_.isEmpty(codes)) {
            err['error_key'] = 'contacts.client.validation.error';
            err['error_field'] = field;
            err['error_codes'] = _.unique(codes);

            if (customFieldType) {
                err['custom_field_type'] = customFieldType;
            }
            // If resci types
            if (resciCustomFieldType) {
                err['resci_custom_field_type'] = resciCustomFieldType;
            }
        }
        return err;
    }

    function monthIsValid(month) {
        return ( month < 13 && month > 0 );
    }

    function dayIsValid(day, month) {
        if (_.isEmpty(month)) {
            return false;
        }
        if (day >= 1 && day <= 31) {
            // Thirty days hath September,
            // April, June and November.
            // All the rest have 31
            // except February which hath 28
            // and sometimes 29
            if (month === '2') {
                return day <= 29;
            }
            else if (month === '4' || month === '6' || month === '9' || month === '11') {
                return day <= 30;
            }
            return true;
        }

        return false;
    }

    function yearIsValid(year, resciCustomFieldType) {
        if(resciCustomFieldType) {
            return (year >= 1900 && year <= 2999);
        }
        return (year >= 1900 && year <= 9999);
    }

    api = {
        isValid(requiredFields, fieldName, value, customFieldType, resciCustomFieldType) {
            let errors = [];

            if (_.contains(requiredFields, fieldName) && _.isEmpty(value)) {
                errors.push('is_missing');
            }

            return _buildErrors(fieldName, errors, customFieldType, resciCustomFieldType);
        },

        isValidEmail(email) {
            let errors = [];

            if (_.isEmpty(email)) {
                // email is always required
                errors.push('is_missing');
            }
            // validate a@b.c format
            if (!/\S+@\S+\.\S+/.test(email)) {
                errors.push('format');
            }

            return _buildErrors('email_address', errors);
        },

        isValidLists(lists) {
            let errors = [];

            if (_.isEmpty(lists)) {
                // lists are always required
                errors.push('is_missing');
            }

            return _buildErrors('list_memberships', errors);
        },

        isValidBirthday(requiredFields, month, day) {
            let errors = [];

            // when birthday is required, month has to be there, day doesn't.
            if (_.contains(requiredFields, 'birthday') &&  _.isEmpty(month) ) {
                errors.push('is_missing');
            }

            // we need a month if there's a day
            if (_.isEmpty(month) && !_.isEmpty(day)) {
                errors.push('blank');
            }

            // if month is there and invalid it's not a valid date
            if (!_.isEmpty(month) && !monthIsValid(month)) {
                errors.push('not_a_date');
            }

            // we don't want to push 'not_a_date' if there is no birthday month
            if (!_.isEmpty(day) && !_.isEmpty(month) && !dayIsValid(day, month)) {
                errors.push('not_a_date');
            }

            return _buildErrors('birthday', errors);
        },

        isValidDate(requiredFields, fieldName, month, day, year, fieldType = false, resciCustomFieldType = false) {
            let errors = [];

            if (_.contains(requiredFields, fieldName) && _.isEmpty(year) && _.isEmpty(month) && _.isEmpty(day)) {
                errors.push('is_missing');
            }
            else if (!_.isEmpty(year) && !_.isEmpty(month) && !_.isEmpty(day)) {
                // all fields are full, validate each field
                if (!monthIsValid(month)) {
                    errors.push('not_a_date');
                }

                if (!dayIsValid(day, month)) {
                    errors.push('not_a_date');
                }

                if (!yearIsValid(year, resciCustomFieldType)) {
                    errors.push('after');
                }
            }
            else if (!_.isEmpty(year) || !_.isEmpty(month) || !_.isEmpty(day)) {
                // need a whole date or no date, eg 10// or //1000
                errors.push('format');
            }

            return _buildErrors(fieldName, errors, fieldType, resciCustomFieldType);
        },
    };

    return api;
})();
