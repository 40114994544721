(function(root, factory) {
    if (window.SignUpFormWidget !== undefined) {
        // safe logger has not been loaded yet
        if (window.console !== undefined) {
            if (window.console.warn && typeof window.console.warn === 'function') {
                window.console.warn('Universal code snippet was installed more than once. Please only include it once on each page.');
            }
            else if (window.console.info && typeof window.console.info === 'function') {
                window.console.info('Universal code snippet was installed more than once. Please only include it once on each page.');
            }
        }
        return; // don't run
    }
    window.SignUpFormWidget = {}; // set a thing in case we run again

    function isES5Compliant(Obj, Arr) {
        return Obj.seal && Obj.create && Arr.isArray && 'x'[0] === 'x';
    }

    // If ES5 is poorly supported in this browser, we will not load.
    if (!isES5Compliant(Object, Array)) return;

    function hasLocalStorage() {
        return window.localStorage && typeof window.localStorage.getItem === 'function' && typeof window.localStorage.setItem === 'function' && typeof window.localStorage.removeItem === 'function';
    }

    // if local storage is not present, do not load
    if (!hasLocalStorage()) return;

    var jquery_lib, jquery_url, underscore_url, css_link, recaptcha_url,
        ctctLibs = {},
        jquery_major = 1,
        jquery_minor = 7,
        underscore_major = 1,
        underscore_minor = 5;

    jquery_url = 'https://web.archive.org/web/20211206224538/https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.4/jquery.js';
    underscore_url = 'https://web.archive.org/web/20211206224538/https://cdnjs.cloudflare.com/ajax/libs/underscore.js/1.8.3/underscore-min.js';
    recaptcha_url = 'https://web.archive.org/web/20211206224538/https://www.google.com/recaptcha/api.js?onload=ctctOnLoadCallback&render=explicit';

    /*
     * SUPPORTED BROWSERS
     * Chrome (Last 3), Firefox (Last 3), Safari (10+), Edge (Last 2), IE11, Samsung
     *
     * WORKING BROWSERS:
     * Chrome (1+), Firefox (21+), Safari (5+), Edge (13+), IE10+, Samsung
     *
     * Q: Why is there a discrepancy?
     * A: Most browsers (Chrome, Firefox, Safari, Edge) are EverGreen, meaning they update
     *    themselves. The vast majority of clients using EverGreen browsers are using
     *    one of the most recent 2 releases of that browser.
     *
     *    We won't block older versions if they work, though, because it would mean more
     *    unnecessary checking just to make our product less universally usable.
      */
    function isSafeBrowser(root) {
        // Matches IE 8+
        var isIE = !!document.documentMode;

        // Matches Edge 13+
        var isEdge = !isIE && !!root.StyleMedia;

        // Matches IE 10+, including Edge
        var isIETenPlus = isEdge || (isIE && root.atob);

        // Matches Chrome 1+
        var isChrome = !!root.chrome && !!root.chrome.webstore;

        // Matches Firefox 21+
        var isFirefox = !!root.InstallTrigger && !!root.location && !!root.location.origin;

        // Check Safari Push Notifications object string representation
        var pushCheck = function(p) { return p.toString() === "[object SafariRemoteNotification]"; };
        var safariNotifications = !root.safari || root.safari.pushNotification;
        var isMobileSafari = typeof root.navigator.standalone !== 'undefined';

        // Matches Safari 3.0+ "[object HTMLElementConstructor]"
        // This serves as our "is this safari?" check: our ES5 check verifies the version.
        var isSafari = /constructor/i.test(window.HTMLElement)
                        || pushCheck(safariNotifications)
                        || isMobileSafari;

        // Matches Browsers powered by Blink engine: it is probably ok to load
        var isBlink = isChrome || (!!root.Intl && !!root.Intl.v8BreakIterator && !!root.CSS);

        // Matches Samsung browser (Stock on galaxy phones)
        var isSamsung = !!root.navigator && /SamsungBrowser/i.test(root.navigator.userAgent);

        // EverGreen browsers update themselves
        var isEverGreen = isFirefox || isSafari || isChrome;

        // Browser must be evergreen, powered by blink, samsung, windows phone, or IE 10+

        // If being used in iframe for resci, should be safe to load.
        var isResciIframe = root.top !== root.self && root.location.hash.indexOf('resci') > -1;

        return isEverGreen || isSamsung || isBlink || isIETenPlus || isResciIframe;
    }

    function semanticVersionIsCorrect(ver, reqMajor, reqMinor) {
        var splitVer = ver.toString().split('.');
        var major = splitVer[0] || 0;
        var minor = splitVer[1] || 0;

        if (major > reqMajor) {
            return true;
        }
        if (major == reqMajor && minor >= reqMinor) {
            return true;
        }

        return false;
    }

    function jQueryIsValid(jQueryOnPage) {
        if (typeof jQueryOnPage !== 'function') {
            return false;
        }

        var j = jQueryOnPage();

        /*
         * a semantic version must have at least (major).(minor)
         * if major version is greater than 1, we're ok
         */
        return (
            j &&
            j.jquery &&
            semanticVersionIsCorrect(j.jquery, jquery_major, jquery_minor)
        );
    }

    function underscoreIsValid(underscoreOnPage) {
        return (
            /*
             * _.VERSION is a private property, so it may be removed in future versions.
             * However, if it is removed, we are clearly in the wrong version.
             * So fail the check.
             */
            underscoreOnPage &&
            underscoreOnPage.VERSION &&
            semanticVersionIsCorrect(underscoreOnPage.VERSION, underscore_major, underscore_minor) &&
            underscoreOnPage.contains &&
            underscoreOnPage.each &&
            underscoreOnPage.findWhere &&
            underscoreOnPage.isEmpty &&
            underscoreOnPage.map &&
            underscoreOnPage.sortBy
        );
    }

    function grecaptchaScriptTagIsOnPage() {
        var grecaptchaScriptTagOnPage = false;

        ctctLibs._.each(document.getElementsByTagName('script'), function(scriptTag) {
            if (!grecaptchaScriptTagOnPage) {
                grecaptchaScriptTagOnPage = (
                    /(google.com\/recaptcha|gstatic.com\/recaptcha)/.test(scriptTag.src) &&
                    (scriptTag.async || scriptTag.defer)
                );
            }
        });

        return grecaptchaScriptTagOnPage;
    }

    function grecaptchaObjectIsValid(grecaptchaOnPage) {
        return (
            grecaptchaOnPage &&
            typeof grecaptchaOnPage.render === 'function' &&
            typeof grecaptchaOnPage.reset === 'function' &&
            typeof grecaptchaOnPage.getResponse === 'function' &&
            typeof grecaptchaOnPage.execute === 'function'
        );
    }

    function grecaptchaIsValid(grecaptchaOnPage) {
        /*
         * We have to detect if the page already has recaptcha v2 on it
         * because recaptcha will break if there are two copies loaded
         *
         * the grecaptcha object on window indicates the library is loaded
         * but sometimes the library takes a while before the object is on window
         *
         * that can lead to collisions if we only check for the grecaptcha object.
         *
         * therefore, we loop over all script tags on the page and see if any of them
         * have 'google.com/recaptcha' or 'gstatic.com/recaptcha' in the src.
         *
         * only if there are no such script tags do we inspect the grecaptcha object.
         */

        return grecaptchaScriptTagIsOnPage() || grecaptchaObjectIsValid(grecaptchaOnPage);
    }

    function loadRecaptchaScript() {
        /**
         * If we're in the product, then we're in previewMode.
         * Not only do we have no need for recaptcha when we are in
         * preview mode, we should never delay the return of the
         * SignUpFormWidget object in wrapper_post by waiting on the
         * result of AJAX calls for dependencies.
         *
         * If we delay the return of the SignUpFormWidget object in
         * wrapper_post, Listgrowth-static will find the SignUpFormWidget
         * object to be undefined when it tries to instantiate the
         * preview API, and the experience will crash.
         */
        ctctLibs.previewMode = window.ListGrowthStaticUI !== undefined;

        if (ctctLibs.previewMode || grecaptchaIsValid(window.grecaptcha)) {
            ctctOnLoadCallback();
        }
        else {
            ctctLibs.$.ajax({
                url: recaptcha_url, // contains onload callback via recaptcha api
                dataType: 'script',
                cache: true
            })
            .fail(function() {
                window.console.error('ERROR: Failed to load ReCaptcha for sign up form script');
            })
        }
    }

    window.ctctOnLoadCallback = function ctctOnLoadCallback() {
        ctctLibs.root.SignUpFormWidget = factory(ctctLibs.root, ctctLibs.$, ctctLibs._, window.grecaptcha, ctctLibs.previewMode);
    };

    /*
     * @param Object event auto-passed this function is an onload callback to $ script
     * this event evaluates to true, so we can't just pass a "skipNoConflict" param
     * for simple boolean checking
     */
    function loadScripts(event) {
        var skipNoConflict = event.skipNoConflict;
        if (!root.jQuery) {
            window.console.error('ERROR: Failed to load jQuery for sign up form script');
            return;
        }

        ctctLibs.$ = skipNoConflict ? root.jQuery : root.jQuery.noConflict();

        if (underscoreIsValid(root._)) {
            ctctLibs._ = root._;
            loadRecaptchaScript();
        } else {
            ctctLibs.$.ajax({
                url: underscore_url,
                dataType: 'script',
                cache: true
            })
            .done(function() {
                ctctLibs._ = root._.noConflict();
                loadRecaptchaScript();
            })
            .fail(function() {
                window.console.error('ERROR: Failed to load underscore dependency');
            });
        }
        return;
    }

    if (isSafeBrowser(root)) {
        ctctLibs.root = root;
        if (jQueryIsValid(root.jQuery)) {
            window.SignUpFormWidget = new jQuery.Deferred();
            loadScripts({ skipNoConflict: true });
        } else {
            jquery_lib = document.createElement('script');
            document.head.appendChild(jquery_lib);
            jquery_lib.async = true;
            jquery_lib.defer = true;
            jquery_lib.onload = loadScripts;
            jquery_lib.src = jquery_url;
        }
    }
}(this, function(root, $, _, grecaptcha, previewMode) {
    'use strict';
