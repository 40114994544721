SignUpFormWidget.Helpers.FlyoutAnimationHelper = {
    getAnimationClass: (position) => {
        let animationDirection;
        switch(position) {
            case 'top-right':
            case 'center-right':
            case 'bottom-right':
                animationDirection = 'right';
                break;
            case 'top-center':
                animationDirection = 'top';
                break;
            case 'bottom-center':
                animationDirection = 'bottom';
                break;
            case 'top-left':
            case 'center-left':
            case 'bottom-left':
                animationDirection = 'left';
                break;
            default:
                animationDirection = 'left';
                break;
        }

        return `animate-from-${animationDirection}`;
    }
};
