SignUpFormWidget.Helpers.BannerFrequencyController = ((() => {
    const DEFAULT_TRIGGER_FREQUENCY = 86400000;

    /**
     * @param timingOpts The timing options on the form.
     * @return {number} Trigger frequency value.
     * @private
     */
    const _getTriggerFrequency = (timingOpts = {}) => {
        return typeof timingOpts.triggerFrequency === 'number' ? timingOpts.triggerFrequency : DEFAULT_TRIGGER_FREQUENCY;
    };

    /**
     * A helper for the local storage key name.
     * @param eventType The event type to use
     * @param formId The formId to use
     * @return {string} The local storage key name.
     * @private
     */
    const _storageKey = (eventType, formId) => {
        return `ctct_banner_last_seen_${eventType}_${formId}`;
    };

    /**
     * Sets the form event last seen time, so we don't show it too often.
     * @param form The form to set the last seen time on.
     * @param eventType The type of event that fired on said form.
     */
    const setFormLastSeenTime = (form, eventType) => {
        window.localStorage.setItem(_storageKey(eventType, form.form_id), Date.now());
    };

    /**
     * Checks the form event last seen time, and makes sure its eligible.
     * @param form The form to check.
     * @param [eventType] The type of event that fired on said form.
     * @return {boolean} Whether the form should be shown or not.
     */
    const formEligibleToBeShown = (form, eventType) => {
        const localStorageValues = {};

        if (eventType === 'button') {
            // if the form was triggered from a manual click, it's eligible to be shown always
            return true;
        }

        // is this form supposed to show on this device type?
        const shouldShow = SignUpFormWidget.Helpers.Devices.showOnThisDevice(form);
        if (!shouldShow) {
            return false;
        }

        const triggerFrequency = _getTriggerFrequency(form.config.timing);

        if (triggerFrequency === 0) {
            return true; // 0 is no limit! Show all the things!
        }

        _.each(SignUpFormWidget.triggerEventTypes, (_eventType) => {
            let val = parseInt(window.localStorage.getItem(_storageKey(_eventType, form.form_id)));
            if (val) { // null is NaN which is false!
               localStorageValues[_eventType] = val;
            }
        });

        if (_.keys(localStorageValues).length === 0) {
            // if there are no local storage values, show all the things
            return true;
        }

        try {
            // grab the earliest existing timestamp from the localStorage object
            const earliestTimestamp = _.min(_.values(localStorageValues));
            const currentTimestamp = Date.now();
            const timeSinceCycleStart = currentTimestamp - earliestTimestamp;

            // if we have passed the triggerFrequency period
            if (timeSinceCycleStart > triggerFrequency) {
                // clear all elements so we do not compare to old cycles and return true
                // this event is now the earliest event of the new cycle
                _.each(SignUpFormWidget.triggerEventTypes, (_eventType) => {
                    window.localStorage.removeItem(_storageKey(_eventType, form.form_id));
                });
                return true;
            }
            else if (eventType !== undefined) {
                // return true if there is no event of this type saved, and false if there is
                return localStorageValues[eventType] === undefined;
            }
            else {
                return false;
            }
        }
        catch (e) {
            // LOL THAT WASN'T A NUMBER
        }
        return false;
    };

    return {
        setFormLastSeenTime,
        formEligibleToBeShown,
    };
})());
