SignUpFormWidget.Helpers.i18n = {
    DEFAULT: "en_US",

    load(language) {
        this.activeLanguage = signup_form_widgeti18n[language] ? language : this.DEFAULT;
    },
    translate(key, parameters) {
        const parse = function ( message, parameters ) {
            return message.replace( /\$(\d+)/g, function ( str, match ) {
                var index = parseInt( match, 10 ) - 1;
                return parameters[ index ] !== undefined ? parameters[ index ] : '$' + match;
            } );
        };
        const param = Array.prototype.slice.call(arguments, 1);
        return parse(signup_form_widgeti18n[this.getActiveLanguage()][key], param);
    },
    getActiveLanguage() {
        return this.activeLanguage || this.DEFAULT;
    },

    getFinePrintTextFromActiveLanguage(customFinePrint) {
        if (!customFinePrint) {
            return signup_form_widgeti18n[this.getActiveLanguage()]['fine_print_default_txt'];
        }
        return customFinePrint;
    }
};
