SignUpFormWidget.Handlers.Style = (function() {
    let api;

    /**
     * Only create the element once. If its already there, reuse it!
     * @param formIndex The index of the form
     * @return {Element} The style element
     * @private
     */
    function _getFormStyleElement(formIndex) {
        let styleId = `ctct-form-styles_${formIndex}`;
        let style = document.getElementById(styleId);

        if (style === null) {
            style = document.createElement('style');
            style.id = styleId;
            style.type = 'text/css';
            style.media = 'all';
            document.head.appendChild(style);
        }

        return style;
    }

    api = {
        buildPopupStyles(formConfig) {
            const { form_index, config } = formConfig;

            let style = _getFormStyleElement(form_index);
            let styleString = ''; // start empty, build it up!

            if (_.isObject(config.title) && _.isString(config.title.color)) {
                styleString += `.ctct-form-popup.form_${form_index} .ctct-form-defaults .ctct-form-header {
                    color: ${config.title.color};
                }\n`;
            }

            if (_.isObject(config.description) && _.isString(config.description.color)) {
                styleString += `.ctct-form-popup.form_${form_index} .ctct-form-defaults .ctct-form-text,
                .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-label,
                .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-listname,
                .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-lists-legend,
                .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-date-slash,
                .ctct-form-popup.form_${form_index} .ctct-form-defaults .ctct-gdpr-text,
                .ctct-form-popup.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
                .ctct-form-popup.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
                    color: ${config.description.color};
                }
                .form_${form_index} .ctct-popup-content {
                    fill: ${config.description.color};
                }\n`;
            }

            if (_.isObject(config.button)) {
                let buttonBg = config.button.backgroundColor;
                let buttonBgActive = config.button.backgroundColorActive;
                let buttonBgHover = config.button.backgroundColorHover;
                let buttonColor = config.button.color;

                styleString += `.ctct-popup-content .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-button {`;
                if (buttonBg) {
                    styleString += `background-color: ${buttonBg};`;
                    styleString += `border: 1px solid ${buttonBg};`;
                }
                if (buttonColor) {
                    styleString += `color: ${buttonColor};`;
                }
                styleString += '}\n';

                if (buttonBg) {
                    styleString += `.ctct-popup-content .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-button:hover {`;
                    if (buttonBgHover) {
                        styleString += `background-color: ${buttonBgHover};`;
                        styleString += `border: 1px solid ${buttonBgHover};`;
                    }
                    styleString += '}\n';

                    styleString += `.ctct-popup-content .ctct-form-popup.form_${form_index} .ctct-form-custom .ctct-form-button:active {`;
                    if (buttonBgActive) {
                        styleString += `background-color: ${buttonBgActive};`;
                        styleString += `border: 1px solid ${buttonBgActive};`;
                    }
                    styleString += '}\n';
                }
            }

            if (_.isObject(config.background) && _.isString(config.background.color)) {
                styleString += `.form_${form_index} .ctct-popup-content,
                 .ctct-form-popup.form_${form_index} .ctct-form-defaults {
                    background-color: ${config.background.color};
                }\n`;
            }

            // update the DOM once we've parsed all the overrides
            style.innerHTML = styleString;
        },
        buildInlineStyles(formConfig) {
            const { form_index, config } = formConfig;

            let style = _getFormStyleElement(form_index);
            let styleString = ''; // start empty, build it up!

            if (_.isObject(config.title) && _.isString(config.title.color)) {
                styleString += `.ctct-form-embed.form_${form_index} .ctct-form-defaults .ctct-form-header {
                    color: ${config.title.color};
                }\n`;
            }

            if (_.isObject(config.description) && _.isString(config.description.color)) {
                styleString += `.ctct-form-embed.form_${form_index} .ctct-form-defaults .ctct-form-text,
                .ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-label,
                .ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-listname,
                .ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-lists-legend,
                .ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-date-slash,
                .ctct-form-embed.form_${form_index} .ctct-form-defaults .ctct-gdpr-text,
                .ctct-form-embed.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
                .ctct-form-embed.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
                    color: ${config.description.color};
                }\n`;
            }

            if (_.isObject(config.button)) {
                let buttonBg = config.button.backgroundColor;
                let buttonBgActive = config.button.backgroundColorActive;
                let buttonBgHover = config.button.backgroundColorHover;
                let buttonColor = config.button.color;

                styleString += `.ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-button {`;
                if (buttonBg) {
                    styleString += `background-color: ${buttonBg};`;
                    styleString += `border: 1px solid ${buttonBg};`;
                }
                if (buttonColor) {
                    styleString += `color: ${buttonColor};`;
                }
                styleString += '}\n';

                if (buttonBg) {
                    styleString += `.ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-button:hover {`;
                    if (buttonBgHover) {
                        styleString += `background-color: ${buttonBgHover};`;
                        styleString += `border: 1px solid ${buttonBgHover};`;
                    }
                    styleString += '}\n';

                    styleString += `.ctct-form-embed.form_${form_index} .ctct-form-custom .ctct-form-button:active {`;
                    if (buttonBgActive) {
                        styleString += `background-color: ${buttonBgActive};`;
                        styleString += `border: 1px solid ${buttonBgActive};`;
                    }
                    styleString += '}\n';
                }
            }

            if (_.isObject(config.background) && _.isString(config.background.color)) {
                styleString += `.ctct-form-embed.form_${form_index} .ctct-form-defaults {
                    background-color: ${config.background.color};
                }\n`;
            }

            // update the DOM once we've parsed all the overrides
            style.innerHTML = styleString;
        },
        buildFlyoutStyles(formConfig) {
            const { form_index, config } = formConfig;

            let style = _getFormStyleElement(form_index);
            let styleString = ''; // start empty, build it up!

            if (_.isObject(config.title) && _.isString(config.title.color)) {
                styleString += `.ctct-form-flyout.form_${form_index} .ctct-form-defaults .ctct-form-header {
                    color: ${config.title.color};
                }\n`;
            }

            if (_.isObject(config.description) && _.isString(config.description.color)) {
                styleString += `.ctct-form-flyout.form_${form_index} .ctct-form-defaults .ctct-form-text,
                .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-label,
                .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-listname,
                .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-lists-legend,
                .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-date-slash,
                .ctct-form-flyout.form_${form_index} .ctct-form-defaults .ctct-gdpr-text,
                .ctct-form-flyout.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
                .ctct-form-flyout.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
                    color: ${config.description.color};
                }
                .form_${form_index} .ctct-flyout-content {
                    fill: ${config.description.color};
                }\n`;
            }

            if (_.isObject(config.button)) {
                let buttonBg = config.button.backgroundColor;
                let buttonBgActive = config.button.backgroundColorActive;
                let buttonBgHover = config.button.backgroundColorHover;
                let buttonColor = config.button.color;

                styleString += `.ctct-flyout-content .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-button {`;
                if (buttonBg) {
                    styleString += `background-color: ${buttonBg};`;
                    styleString += `border: 1px solid ${buttonBg};`;
                }
                if (buttonColor) {
                    styleString += `color: ${buttonColor};`;
                }
                styleString += '}\n';

                if (buttonBg) {
                    styleString += `.ctct-flyout-content .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-button:hover {`;
                    if (buttonBgHover) {
                        styleString += `background-color: ${buttonBgHover};`;
                        styleString += `border: 1px solid ${buttonBgHover};`;
                    }
                    styleString += '}\n';

                    styleString += `.ctct-flyout-content .ctct-form-flyout.form_${form_index} .ctct-form-custom .ctct-form-button:active {`;
                    if (buttonBgActive) {
                        styleString += `background-color: ${buttonBgActive};`;
                        styleString += `border: 1px solid ${buttonBgActive};`;
                    }
                    styleString += '}\n';
                }
            }

            if (_.isObject(config.background) && _.isString(config.background.color)) {
                styleString += `.form_${form_index} .ctct-flyout-content,
                 .ctct-form-flyout.form_${form_index} .ctct-form-defaults {
                    background-color: ${config.background.color};
                }\n`;
            }
            style.innerHTML = styleString;
        },
        buildBannerStyles(formConfig) {
            const { form_index, config } = formConfig;

            let style = _getFormStyleElement(form_index);
            let styleString = ''; // start empty, build it up!

            if (_.isObject(config.title) && _.isString(config.title.color)) {
                styleString += `.ctct-form-banner.form_${form_index} .ctct-form-defaults .ctct-form-header {
                    color: ${config.title.color};
                }\n`;
            }

            if (_.isObject(config.description) && _.isString(config.description.color)) {
                styleString += `.ctct-form-banner.form_${form_index} .ctct-form-defaults .ctct-form-text,
                .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-label,
                .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-listname,
                .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-lists-legend,
                .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-date-slash,
                .ctct-form-banner.form_${form_index} .ctct-form-defaults .ctct-gdpr-text,
                .ctct-form-banner.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-link,
                .ctct-form-banner.form_${form_index} .ctct-form-defaults .ctct-gdpr-text .ctct-form-footer-privacy-link {
                    color: ${config.description.color};
                }
                .form_${form_index} .ctct-banner-content {
                    fill: ${config.description.color};
                }\n`;
            }

            if (_.isObject(config.button)) {
                let buttonBg = config.button.backgroundColor;
                let buttonBgActive = config.button.backgroundColorActive;
                let buttonBgHover = config.button.backgroundColorHover;
                let buttonColor = config.button.color;

                styleString += `.ctct-banner-content .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-button {`;
                if (buttonBg) {
                    styleString += `background-color: ${buttonBg};`;
                    styleString += `border: 1px solid ${buttonBg};`;
                }
                if (buttonColor) {
                    styleString += `color: ${buttonColor};`;
                }
                styleString += '}\n';

                if (buttonBg) {
                    styleString += `.ctct-banner-content .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-button:hover {`;
                    if (buttonBgHover) {
                        styleString += `background-color: ${buttonBgHover};`;
                        styleString += `border: 1px solid ${buttonBgHover};`;
                    }
                    styleString += '}\n';

                    styleString += `.ctct-banner-content .ctct-form-banner.form_${form_index} .ctct-form-custom .ctct-form-button:active {`;
                    if (buttonBgActive) {
                        styleString += `background-color: ${buttonBgActive};`;
                        styleString += `border: 1px solid ${buttonBgActive};`;
                    }
                    styleString += '}\n';
                }
            }

            if (_.isObject(config.background) && _.isString(config.background.color)) {
                styleString += `.form_${form_index} .ctct-banner-content,
                 .ctct-form-banner.form_${form_index} .ctct-form-defaults {
                    background-color: ${config.background.color};
                }\n`;
            }

            // update the DOM once we've parsed all the overrides
            style.innerHTML = styleString;
        },
        loadBaseStyles() {
            // grab src from script tag and replace the asset name with css
            const mainScript = $("script[src$='signup-form-widget.min.js'], script[src$='signup-form-widget.js']");
            if (!mainScript.length) {
                SignUpFormWidget.Helpers.Logger.error('Failed to find main sign up form script, aborting.');
                return;
            }

            const baseSrc = mainScript.attr('src');
            const styleHref = baseSrc.replace('signup-form-widget.min.js', 'signup-form-widget.css')
                .replace('signup-form-widget.js', 'signup-form-widget.css');

            if (typeof SignUpFormWidget.env !== 'string') {
                SignUpFormWidget.Helpers.Env.parseEnv(baseSrc);
            }

            let css_link = document.createElement('link');
            css_link.rel = 'stylesheet';
            css_link.type = 'text/css';
            css_link.media = 'all';
            css_link.href = styleHref;

            document.head.appendChild(css_link);
        },
    };

    return api;
})();
