SignUpFormWidget.Helpers.Cookie = {
    getCookies: () => {
    const cookies = {};
    if (document.cookie && document.cookie !== '') {
      const split = document.cookie.split(';');
      let i = -1;
  
      while (++i < split.length) {
        const name_value = split[i].split('=');
        name_value[0] = name_value[0].replace(/^ /, '');
        try {
          const value = name_value.slice(1, name_value.length).join('=');
          cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(value);
        } catch (error) {
          if (typeof console !== 'undefined' && console !== null) {
            window.console.log(error);
          }
        }
      }
    }
    return cookies;
  }
};
