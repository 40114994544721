SignUpFormWidget.Helpers.Collector = (() => {
    let api;

    function _getCustomFieldsInputs(customFields, formIndex, requiredFields, errors) {
        let inputs = [], value, month, day, year, error;

        _.each(customFields, (customField) => {
            /*
             * Reset value in case we don't collect a date and in case
             * it retains the value of a custom field we just collected.
             */
            value = '';
            if (customField.type === 'custom_field_date') {
                month = $(`#${customField.type}_${customField.name}_month_${formIndex}`).val();
                day = $(`#${customField.type}_${customField.name}_day_${formIndex}`).val();
                year = $(`#${customField.type}_${customField.name}_year_${formIndex}`).val();

                error = SignUpFormWidget.Validation.Form.isValidDate(requiredFields, `${customField.type}_${customField.name}`, month, day, year, customField.type);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
                else if (!_.isEmpty(month) && !_.isEmpty(day) && !_.isEmpty(year)) {
                    value = `${month}/${day}/${year}`;
                }
            }
            else {
                value = $(`#${customField.type}_${customField.name}_${formIndex}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, `${customField.type}_${customField.name}`, value, customField.type);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
            }

            if (!_.isEmpty(value)) {
                inputs.push({
                    custom_field_id: customField.id,
                    value,
                });
            }
        });

        return inputs;
    }

    function _getStreetAddressInputs(requiredFields, contactFields, formIndex, errors) {
        let inputs = { }, value, error;

        if (_.contains(contactFields, 'country')) {
            value = $(`#country_${formIndex}`).val();

            error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'country', value);
            if (!_.isEmpty(error)) {
                errors.push(error);
            }
            else if (!_.isEmpty(value)) {
                inputs.country = value;
            }
        }

        if (_.contains(contactFields, 'street')) {
            value = $(`#street_${formIndex}`).val();

            error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'street', value);
            if (!_.isEmpty(error)) {
                errors.push(error);
            }
            else if (!_.isEmpty(value)) {
                inputs.street = value;
            }
        }

        if (_.contains(contactFields, 'city')) {
            value = $(`#city_${formIndex}`).val();

            error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'city', value);
            if (!_.isEmpty(error)) {
                errors.push(error);
            }
            else if (!_.isEmpty(value)) {
                inputs.city = value;
            }
        }

        if (_.contains(contactFields, 'state')) {
            value = $(`#state_${formIndex}:visible`).val();

            error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'state', value);
            if (!_.isEmpty(error)) {
                errors.push(error);
            }
            else if (!_.isEmpty(value)) {
                inputs.state = value;
            }
        }

        if (_.contains(contactFields, 'postal_code')) {
            value = $(`#postal_code_${formIndex}`).val();

            error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'postal_code', value);
            if (!_.isEmpty(error)) {
                errors.push(error);
            }
            else if (!_.isEmpty(value)) {
                inputs.postal_code = value;
            }
        }

        return inputs;
    }

    function _getResciCustomFieldsInputs(resciCustomFeilds, formIndex, requiredFields, errors) {
        let inputs = {}, value, month, day, year, error;

        _.each(resciCustomFeilds, (customField) => {
            /*
             * Reset value in case we don't collect a date and in case
             * it retains the value of a resci custom field we just collected.
             */
            value = '';
            if (customField.type === 'DATETIME' || customField.type === 'DATE') {
                month = $(`#resci_${customField.type}_${customField.name}_month_${formIndex}`).val();
                day = $(`#resci_${customField.type}_${customField.name}_day_${formIndex}`).val();
                year = $(`#resci_${customField.type}_${customField.name}_year_${formIndex}`).val();

                error = SignUpFormWidget.Validation.Form.isValidDate(requiredFields, `resci_${customField.type}_${customField.name}`, month, day, year, null, customField.type);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
                else if (!_.isEmpty(month) && !_.isEmpty(day) && !_.isEmpty(year)) {
                    // If single number entered for date and month, prefix with zero to avoid API errors.
                    if(month.length === 1) {
                        month = `0${month}`;
                    }
                    if(day.length === 1) {
                        day = `0${day}`;
                    }

                    // TODO: temporary solution to support DATETIME, adding default TIME values. 
                    // This should be updated once we support new input types.
                    value = customField.type === 'DATETIME' ? `${year}-${month}-${day} 00:00:00` : `${year}-${month}-${day}`;
                }
            }
            else {
                value = $(`#resci_${customField.type}_${customField.name}_${formIndex}`).val();
                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, `resci_${customField.type}_${customField.name}`, value, null,  customField.type);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
            }
            // Based on name space group the values of resci custom fields.
            if (!_.isEmpty(value)) {
                if(inputs[customField.namespace]) {
                    inputs[customField.namespace][customField.name] = value;
                } else {
                    inputs[customField.namespace] = {
                        [customField.name]: value
                    };
                }
            }
        });

        return inputs;
    }
    api = {
        /**
         * Collects inputs and does client side validation. Will call to display errors if there are any.
         *
         * @param {Object} formObj Form object
         * @returns {Object} Returns array of objects with field name/value, or empty if there's errors.
         */
        getFormInputs(formObj) {
            let inputs = {}, error, errors = [], value, month, day, year;

            const { contact_fields, list_ids, form_index } = formObj;
            const configCustomFields = SignUpFormWidget.Handlers.Config.getCustomFields(formObj);
            const requiredFields = SignUpFormWidget.Handlers.Config.getRequiredFields(formObj);
            const configResciCustomFields = SignUpFormWidget.Handlers.Config.getResciCustomFields(formObj);

            value = $(`#email_address_${form_index}`).val();
            error = SignUpFormWidget.Validation.Form.isValidEmail(value);
            inputs.email_address = value;
            if (!_.isEmpty(error)) {
                errors.push(error);
            }

            // there should always be lists
            let list_memberships = [];
            if (list_ids.length > 1) {
                let selectedLists = $('.ctct-form-lists input:checked');
                _.each(selectedLists, (selectedList) => {
                    list_memberships.push($(selectedList).val());
                });
            }
            else {
                list_memberships.push(list_ids[ 0 ]);
            }
            error = SignUpFormWidget.Validation.Form.isValidLists(list_memberships);
            inputs.list_memberships = list_memberships;
            if (!_.isEmpty(error)) {
                errors.push(error);
            }

            if (_.contains(contact_fields, 'first_name')) {
                value = $(`#first_name_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'first_name', value);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                } else if (!_.isEmpty(value)) {
                    inputs.first_name = value;
                }
            }

            if (_.contains(contact_fields, 'last_name')) {
                value = $(`#last_name_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'last_name', value);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                } else if (!_.isEmpty(value)) {
                    inputs.last_name = value;
                }
            }

            if (_.contains(contact_fields, 'job_title')) {
                value = $(`#job_title_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'job_title', value);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                } else if (!_.isEmpty(value)) {
                    inputs.job_title = value;
                }
            }

            if (_.contains(contact_fields, 'company')) {
                value = $(`#company_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'company', value);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                } else if (!_.isEmpty(value)) {
                    inputs.company_name = value;
                }
            }

            if (_.contains(contact_fields, 'phone')) {
                value = $(`#phone_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValid(requiredFields, 'phone', value);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                } else if (!_.isEmpty(value)) {
                    inputs.phone_number = value;
                }
            }

            if (!_.isEmpty(configCustomFields)) {
                let customFields = _getCustomFieldsInputs(configCustomFields, form_index, requiredFields, errors);
                if (!_.isEmpty(customFields)) {
                    inputs.custom_fields = customFields;
                }
            }

            if(!_.isEmpty(configResciCustomFields)) {
                let resciCustomFields = _getResciCustomFieldsInputs(configResciCustomFields, form_index, requiredFields, errors);
                if (!_.isEmpty(resciCustomFields)) {
                    inputs.resci_custom = resciCustomFields;
                }
            }

            if (_.contains(contact_fields, 'birthday')) {
                month = $(`#birthday_month_${form_index}`).val();
                day = $(`#birthday_day_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValidBirthday(requiredFields, month, day);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
                // if both are empty, don't send the data in post
                else if (!_.isEmpty(month) || !_.isEmpty(day)) {
                    inputs.birthday_day = day;
                    inputs.birthday_month = month;
                }
            }

            if (_.contains(contact_fields, 'anniversary')) {
                month = $(`#anniversary_month_${form_index}`).val();
                day = $(`#anniversary_day_${form_index}`).val();
                year = $(`#anniversary_year_${form_index}`).val();

                error = SignUpFormWidget.Validation.Form.isValidDate(requiredFields, 'anniversary', month, day, year);
                if (!_.isEmpty(error)) {
                    errors.push(error);
                }
                else if (!_.isEmpty(month) && !_.isEmpty(day) && !_.isEmpty(year)) {
                    inputs.anniversary = `${month}/${day}/${year}`;
                }
            }

            // street address needs a special object. Only add it if we're doing address things
            let streetAddress = _getStreetAddressInputs(requiredFields, contact_fields, form_index, errors);
            if (!_.isEmpty(streetAddress)) {
                inputs.street_address = streetAddress;
            }

            if (_.isEmpty(errors)) {
                return inputs;
            }
            else {
                SignUpFormWidget.Handlers.Error.display(formObj, errors);
                return {};
            }
        },
    };

    return api;
})();
