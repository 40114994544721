SignUpFormWidget.Handlers.Config = (function() {
    const TEST_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    let config = {}, api;

    function _getContactFields(contactFields, config) {
        const contactFieldConfig = _.where(config.contactFields, { customField: false });
        return _.filter(contactFieldConfig, (contactField) => {
            // ensure top level config contains contact field and wasn't removed
            return _.contains(contactFields, contactField.type);
        });
    }

    function _getCustomFields(customFields, config) {
        const customFieldConfig = _.where(config.contactFields, { customField: true });
        return _.filter(customFieldConfig, (customField) => {
            // ensure top level config contains custom field id and wasn't removed
            return _.contains(customFields, customField.id);
        });
    }

    function _getResciCustomFields(fieldIds, inputConfig) {
        const customFieldConfig = _.where(inputConfig.contactFields, { resciCustomField: true });
        // TODO: will re-enable once the approach is finalized, not will show all the custom fields
        // based on config.
        // return _.filter(customFieldConfig, (customField) => {
        //     // Make sure id which is name of resci custom field exist in master list/
        //     return _.contains(fieldIds, customField.id);
        // });
        return customFieldConfig;
    }

    function _getEmailLists(listIds, config) {
        return _.filter(config.emailLists, (emailList) => {
            // ensure top level config contains list id and wasn't removed
            return _.contains(listIds, emailList.id);
        });
    }

    function _sortAndPinCountries(countries) {
        // Keep a list of countries to be pinned to the top of the select
        // dropdown. This is the order they will be displayed
        const countriesToPin = [
            { code: 'us', obj: undefined },
            { code: 'ca', obj: undefined },
        ];

        let sortedCountries = _.sortBy(countries, 'display');

        // Loop through the supplied countries and
        // If country is unpinned, add to the unpinned list
        // If country is pinned, update the object reference in countriesToPin
        //
        let unpinnedCountries = [];
        _.each(sortedCountries, function(country) {
            let pinned = false;
            _.each(countriesToPin, function(pinnedCountry) {
                if (pinnedCountry.code === country.countryCode) {
                    pinnedCountry.obj = country;
                    pinned = true;
                }
            });

            if (!pinned) {
                unpinnedCountries.push(country);
            }
        });

        //  Extract the country object from the sorted pinned country list
        let pinnedList = _.map(countriesToPin, function(country) {
            return country.obj;
        });

        // Throw away any pinned countries that weren't supplied by the service
        let validPinnedList = _.reject(pinnedList, function(country) {
            return country === undefined;
        });

        // Cocatenate the unpinned and pinned lists and return
        return validPinnedList.concat(unpinnedCountries);
    }

    function _getCompanyInfo(companyName, physicalAddress, orgWebsite) {
        let companyInfoParts = [];
        if (companyName) {
            companyInfoParts.push(companyName);
        }
        if (physicalAddress) {
            const addressElements = [
                'addressLine1',
                'addressLine2',
                'addressLine3',
                'city',
                'stateCode',
                'stateName',
                'postalCode'
            ];
            _.each(addressElements, function (el) {
                if (physicalAddress[el]) {
                    companyInfoParts.push(physicalAddress[el]);
                }
            });
            if (physicalAddress['countryCode']) {
                companyInfoParts.push(physicalAddress['countryCode'].toUpperCase());
            }
        }
        if (orgWebsite && orgWebsite !== 'null') {
            companyInfoParts.push(orgWebsite);
        }
        return companyInfoParts.join(', ');
    }

    api = {
        /**
        * Load config from s3 to get form data and other misc data needed (states, countries, etc)
        */
        loadConfig() {
            let deferred = $.Deferred();

            if (!SignUpFormWidget.previewMode && (_ctct_m === undefined || typeof _ctct_m !== 'string')) {
                // if we're not in preview mode and _ctct_m is not there, do nothing because we can't do anything anyways
                SignUpFormWidget.Helpers.Logger.error('Missing critical variable "_ctct_m". Please copy paste universal code from account again.');
                deferred.reject('Missing "_ctct_m".');
                return;
            }

            const configUrl = `https://web.archive.org/web/20211206224538/https://listgrowth${SignUpFormWidget.assetEnv}.ctctcdn.com/v1/${_ctct_m}.json`;

            let xhr = $.ajax({
                url: configUrl,
            });
            xhr.done((data, textStatus, jqXHR) => {
                const companyInfo = _getCompanyInfo(data.companyName, data.physicalAddress, data.orgWebsite);
                api.setCompanyName(data.companyName);
                api.setPhysicalAddress(data.physicalAddress);
                api.setOrgWebsite(data.orgWebsite);
                api.setPrivacyUrl(data.privacyUrl);
                api.setCustomPrivacyUrl(data.customPrivacyPolicyUrl);
                api.setFinePrintText(data.finePrintText);
                api.setServiceUrl('https://web.archive.org/web/20211206224538/https://www.constantcontact.com/legal/service-provider');
                api.setCountries(data.countries);
                api.setStates(data.states);
                api.setForms(data.forms);

                const recaptchaHeader = jqXHR.getResponseHeader('recaptcha-key');
                const shouldUseTestKey = SignUpFormWidget.Helpers.Env.isUsingTestRecaptchaKey();

                if (!_.isString(recaptchaHeader)) { // null is the kill switch
                    api.setRecaptchaKey(null);
                }
                else if (shouldUseTestKey) {
                    api.setRecaptchaKey(TEST_KEY);
                }
                else { // it's the real deal!
                    api.setRecaptchaKey(recaptchaHeader);
                }

                deferred.resolve({forms: api.getForms(), companyInfo: companyInfo});
            });
            xhr.fail((jqXHR, textStatus, errorThrown) => {
                deferred.reject(false, `Failure to retrieve config from ${configUrl}, aborting.`);
            });

            return deferred.promise();
        },

        setRecaptchaKey(recaptchaKey) {
            config.recaptchaKey = recaptchaKey;
        },

        getRecaptchaKey() {
            return config.recaptchaKey;
        },

        getLanguage() {
            return config.language;
        },

        getCompanyName() {
            return config.companyName;
        },

        setCompanyName(companyName) {
            config.companyName = companyName;
        },

        getPhysicalAddress() {
            return config.physicalAddress;
        },

        setPhysicalAddress(physicalAddress) {
            config.physicalAddress = physicalAddress;
        },

        getOrgWebsite() {
            return config.orgWebsite;
        },

        setOrgWebsite(orgWebsite) {
            config.orgWebsite = orgWebsite;
        },

        getPrivacyUrl() {
            return config.privacyUrl;
        },

        getCustomPrivacyUrl() {
            if (config.customPrivacyUrl !== 'null') {
                return config.customPrivacyUrl;
            }
        },

        getFinePrintText() {
            return config.finePrintText;
        },

        setFinePrintText(finePrintText) {
            config.finePrintText = finePrintText;
        },

        getServiceUrl() {
            return config.serviceUrl;
        },

        setPrivacyUrl(privacyUrl) {
            config.privacyUrl = privacyUrl;
        },

        setCustomPrivacyUrl(customPrivacyUrl) {
            config.customPrivacyUrl = customPrivacyUrl;
        },

        setServiceUrl(serviceUrl) {
            config.serviceUrl = serviceUrl;
        },

        getCompanyInfo () {
            return _getCompanyInfo(config.companyName, config.physicalAddress, config.orgWebsite);
        },

        getCountries() {
            return config.countries;
        },

        setCountries(countries) {
            config.countries = _sortAndPinCountries(countries);
        },

        getStates() {
            return config.states;
        },

        setStates(states) {
            config.states = states;
        },

        getForms() {
            return config.forms;
        },

        setForms(forms) {
            let formsArr = [];
            _.each(forms, (form, index) => {
                // parse the form config for each form as it comes in
                try {
                    form.config = JSON.parse(form.config);
                    form.form_index = index;
                    formsArr.push(form);
                }
                catch (err) {
                    // reject the form if the config is not valid
                    SignUpFormWidget.Helpers.Logger.warn(`Form "${form.name}" has malformed configuration, please republish this form.`);
                }
            });
            config.forms = formsArr;
        },

        getFormById(formId) {
            return _.findWhere(config.forms, { form_id: formId});
        },

        getCustomFields(formConfig) {
            return _getCustomFields(formConfig.custom_field_ids, formConfig.config);
        },
        getResciCustomFields(formConfig) {
            return _getResciCustomFields(formConfig.resci_custom_field_ids, formConfig.config);
        },

        /**
        * Gets the custom field type by name
        *
        * @param {Object} formConfig the form we want to scope our query to
        * @param {String} name the name of the field whose type we want
        * @returns {String} The type of the field (date or string)
        */
        getCustomFieldType(formConfig, name) {
            const customFields = this.getCustomFields(formConfig);
            const matchedField = _.findWhere(customFields, { name });

            return matchedField ?  matchedField.type : matchedField;
        },

        /**
        * Gets the custom field type by name
        *
        * @param {Object} formConfig the form we want to scope our query to
        * @param {String} name the name of the field whose type we want
        * @returns {String} The type of the field (date or string)
        */
         getResciCustomFieldType(formConfig, name) {
            const customFields = this.getResciCustomFields(formConfig);
            const matchedField = _.findWhere(customFields, { name });

            return matchedField ?  matchedField.type : matchedField;
        },

        /**
        * Parses out data needed for rendering from the form
        *
        * @param {Object} formConfig the form we want to render
        * @param {String} formConfig.config form must contain a stringified object as 'config' prop
        * @returns {Object} Contains all properties needed for rendering the form.
        */
        getRenderConfig(formConfig, companyInfo) {
            if (!_.isObject(formConfig) || !_.isObject(formConfig.config)) {
                return undefined; // this is not a valid case
            }

            let renderConfig = $.extend({}, formConfig.config, true);

            renderConfig.list_ids = formConfig.list_ids;
            renderConfig.contact_fields = formConfig.contact_fields;
            renderConfig.custom_field_ids = formConfig.custom_field_ids;
            renderConfig.form_id = formConfig.form_id;
            renderConfig.form_index = formConfig.form_index;
            renderConfig.list_ids = formConfig.list_ids;
            renderConfig.type = formConfig.type;
            
            renderConfig.company_info = companyInfo;

            renderConfig.titleText = _.isObject(formConfig.config.title) ? formConfig.config.title.text : '';
            renderConfig.descriptionText = _.isObject(formConfig.config.description) ? formConfig.config.description.text : '';
            renderConfig.buttonText = _.isObject(formConfig.config.button) ? formConfig.config.button.text : '';

            let emailLists = _getEmailLists(formConfig.list_ids, renderConfig);
            renderConfig._emailLists = emailLists.sort(function(l1, l2) {
                return l1.label.toLowerCase() > l2.label.toLowerCase() ? 1 : -1;
            });
            renderConfig._customFields = _getCustomFields(formConfig.custom_field_ids, renderConfig);
            renderConfig._resciCustomFields = _getResciCustomFields(formConfig.resci_custom_field_ids, renderConfig);


            if (formConfig.type === 'FLYOUT') {
                renderConfig.position = formConfig.config.flyout.position;
                renderConfig.animated = formConfig.config.flyout.animated;
            }

            if (formConfig.config.hasOwnProperty('branding')) {
                renderConfig.hideBranding = formConfig.config.branding.hideBranding || false;
            } else {
                renderConfig.hideBranding = false;
            }

            renderConfig._defaults = {
                countries: SignUpFormWidget.Handlers.Config.getCountries(),
                states: SignUpFormWidget.Handlers.Config.getStates(),
                privacyUrl: SignUpFormWidget.Handlers.Config.getPrivacyUrl(),
                customPrivacyUrl: SignUpFormWidget.Handlers.Config.getCustomPrivacyUrl(),
                finePrintText: SignUpFormWidget.Handlers.Config.getFinePrintText(),
                serviceUrl: SignUpFormWidget.Handlers.Config.getServiceUrl(),
            };
            if (_.contains(renderConfig.contact_fields, 'anniversary')) {
                renderConfig.max_anniversary_year = SignUpFormWidget.Helpers.Date.getMaxAnniversaryYear();
            }
            renderConfig.recaptchaKey = SignUpFormWidget.Handlers.Config.getRecaptchaKey();

            return renderConfig;
        },

        /**
        * Parses out list of required fields from form
        *
        * @param {Object} formConfig the form we want to get data from
        * @returns {Array} Contains array of fields marked required
        */
        getRequiredFields(formConfig = { config: '{"contactFields":[]}' }) {
            let reqFields = [], contactFields, customFields, resciCustomFields;

            let fieldsConfig = $.extend({}, formConfig.config, true);

            contactFields = _getContactFields(formConfig.contact_fields, fieldsConfig);
            customFields = _getCustomFields(formConfig.custom_field_ids, fieldsConfig);

            resciCustomFields = _getResciCustomFields(formConfig.resci_custom_field_ids, fieldsConfig);


            _.each(contactFields, (contactField) => {
                if (contactField.required) {
                    reqFields.push(contactField.type);
                }
            });

            _.each(customFields, (customField) => {
                if (customField.required) {
                    reqFields.push(`${customField.type}_${customField.name}`);
                }
            });
            // Helps for validation.
            _.each(resciCustomFields, (resciCustomField) => {
                if (resciCustomField.required) {
                    reqFields.push(`resci_${resciCustomField.type}_${resciCustomField.name}`);
                }
            });

            return reqFields;
        },

    };

    return api;
})();
